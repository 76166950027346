import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

//components
import NavBar from "../components/NavBar";
import NavBarMobile from "../components/NavBarMobile";
import Footer from "../components/Footer";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

//icons
import { MoneyEuroCircle } from "@styled-icons/remix-line/MoneyEuroCircle";
import { OpenBook } from "@styled-icons/entypo/OpenBook";
import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";
import { Person } from "@styled-icons/bootstrap/Person";
import { Tent } from "@styled-icons/fluentui-system-filled/Tent";
import { Trailer } from "@styled-icons/fa-solid/Trailer";
import { House } from "@styled-icons/fa-solid/House";
import { ArrowLeftCircle } from "@styled-icons/bootstrap/ArrowLeftCircle";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";
import { PhoneCall } from "@styled-icons/boxicons-regular/PhoneCall";

//api
import {
  getAllDataDashboard,
  getReservationRevenueApi,
  getReservationTotalCountApi,
  getNotificationsApi,
} from "../../api/api-list";

//hooks
import useAuth from "../hooks/useAuth";
import { motion } from "framer-motion";

const Dashboard = () => {
  //auth
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  const variants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };
  //auth -- end

  const [dataDashboard, setDataDashboard] = useState({});

  const getDataDashboard = () => {
    const apiUrl = `${getAllDataDashboard}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        console.log(response.data);
        setDataDashboard(response.data);
      });
  };

  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    const apiUrl = `${getNotificationsApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        // console.log(response.data);

        const data = response.data;

        const addThreeHours = (reservations) => {
          return reservations.map((reservation) => ({
            ...reservation,
            created_at: moment(reservation.created_at)
              .add(3, "hours")
              .format("YYYY-MM-DD HH:mm:ss"),
          }));
        };

        const updatedNotifications = addThreeHours(data);

        // console.log(updatedNotifications);

        setNotifications(updatedNotifications);
        setLoading(false);
      });
  };

  const [statisticsSelected, setStatisticsSelected] = useState("Revenue");

  const [reservationRevenue, setReservationRevenue] = useState([]);

  const getReservationRevenue = () => {
    const apiUrl = `${getReservationRevenueApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        setReservationRevenue(response.data);
      });
  };

  const [reservationCount, setReservationCount] = useState([]);

  const getReservationCount = () => {
    const apiUrl = `${getReservationTotalCountApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        setReservationCount(response.data);
      });
  };

  const [date, setDate] = useState(null);
  const [dateShort, setDateShort] = useState(null);

  const getCurrentDate = () => {
    const daysOfWeek = [
      "Svētdiena",
      "Pirmdiena",
      "Otrdiena",
      "Trešdiena",
      "Ceturtdiena",
      "Piektdiena",
      "Sestdiena",
    ];

    const today = new Date();
    const dayOfWeek = daysOfWeek[today.getDay()];
    const dayOfMonth = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();

    setDate(`${dayOfWeek}, ${dayOfMonth}.${month}.${year}`);
    setDateShort(`${dayOfMonth}.${month}.${year}`);
  };

  const calculateStayDuration = (checkIn, checkOut) => {
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = checkOutDate - checkInDate;

    // Convert the difference from milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Return the stay duration excluding the check-out date
    return `, ${differenceInDays} nakts/-is`;
  };

  const reservationsToShow = 5;

  useEffect(() => {
    if (auth !== null) {
      if (!auth) {
        navigate("/admin/login");
      } else {
        getCurrentDate();
        getDataDashboard();
        getReservationRevenue();
        getReservationCount();
        getNotifications();
      }
    }

    //eslint-disable-next-line
  }, [auth]);

  return (
    <Wrapper>
      {!loading && (
        <>
          <NavBarMobile />
          <motion.div initial="hidden" animate="visible" variants={variants}>
            <Sticky>
              <NavBar />
              <RouterAndTime>
                <div className="router-time-container">
                  <div className="heading-router">
                    <p>Vadība</p>
                    <Link to="/admin">/ Panelis</Link>
                  </div>

                  <div className="heading-date">
                    <p>{date}</p>
                  </div>
                </div>
              </RouterAndTime>
            </Sticky>
            <Container>
              <div className="panel">
                <h3>Pārskats</h3>

                <ReservationPreview>
                  <Arrivals>
                    <div className="widget-icon">
                      <div className="widget-icon-wrapper">
                        <ArrowLeftCircle className="icon" />
                      </div>

                      <div className="widget-data">
                        <h3 className="widget-name">
                          Šodien iebrauc ({dataDashboard?.arrivals?.length})
                        </h3>

                        {dataDashboard?.arrivals?.length >
                          reservationsToShow && (
                          <>
                            <Link to="/admin/calendar">Apskatīt visu</Link>
                          </>
                        )}
                      </div>
                    </div>

                    {dataDashboard?.arrivals?.length > 0 ? (
                      <>
                        {dataDashboard?.arrivals
                          ?.slice(0, reservationsToShow)
                          ?.map((reservation) => (
                            <div className="reservation-preview">
                              <div className="reservation-preview-single">
                                <div className="reservation-preview-single-head">
                                  <div className="reservation-preview-single-head-left">
                                    <div className="reservation-preview-single-head-left-icon">
                                      {reservation?.accommodation_id < 13 && (
                                        <>
                                          <House className="icon" />
                                        </>
                                      )}
                                      {reservation?.accommodation_id === 13 && (
                                        <>
                                          <Tent className="icon" />
                                        </>
                                      )}
                                      {reservation?.accommodation_id === 14 && (
                                        <>
                                          <Trailer className="icon" />
                                        </>
                                      )}
                                    </div>

                                    <div className="reservation-preview-single-head-left-data">
                                      <h4 className="reservation-preview-single-name">
                                        {reservation?.name}
                                      </h4>
                                      <h5 className="reservation-preview-single-place">
                                        {reservation?.accommodation_id < 13 && (
                                          <>{`${reservation?.accommodation_id}. mājiņa`}</>
                                        )}
                                        {reservation?.accommodation_id ===
                                          13 && <>Telts vieta</>}
                                        {reservation?.accommodation_id ===
                                          14 && <>Treilera vieta</>}

                                        {calculateStayDuration(
                                          reservation?.check_in_date,
                                          reservation?.check_out_date
                                        )}
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="reservation-preview-single-head-right">
                                    <Link
                                      to={`tel:${reservation?.phone_number}`}
                                      className="reservation-preview-single-head-left-icon"
                                    >
                                      <PhoneCall className="icon" />
                                    </Link>
                                  </div>
                                </div>

                                <div className="reservation-preview-single-comment">
                                  {reservation?.notes?.length > 0 ? (
                                    <>
                                      <p>{reservation?.notes}</p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="no-comment">
                                        Nav atstāts komentārs
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                        {dataDashboard?.arrivals?.length >
                          reservationsToShow && (
                          <>
                            <p className="reservation-preview-more">
                              +{" "}
                              {dataDashboard?.arrivals?.length -
                                reservationsToShow}{" "}
                              rezervācija/-s
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="reservation-preview-none">
                          <p>Nav atrastu pozīciju</p>
                        </div>
                      </>
                    )}
                  </Arrivals>

                  <Leaving>
                    <div className="widget-icon">
                      <div className="widget-icon-wrapper">
                        <ArrowRightCircle className="icon" />
                      </div>

                      <div className="widget-data">
                        <h3 className="widget-name">
                          Šodien izbrauc ({dataDashboard?.leaving?.length})
                        </h3>

                        {dataDashboard?.leaving?.length >
                          reservationsToShow && (
                          <>
                            <Link to="/admin/calendar">Apskatīt visu</Link>
                          </>
                        )}
                      </div>
                    </div>

                    {dataDashboard?.leaving?.length > 0 ? (
                      <>
                        {dataDashboard?.leaving
                          ?.slice(0, reservationsToShow)
                          ?.map((reservation) => (
                            <div className="reservation-preview">
                              <div className="reservation-preview-single">
                                <div className="reservation-preview-single-head">
                                  <div className="reservation-preview-single-head-left">
                                    <div className="reservation-preview-single-head-left-icon">
                                      {reservation?.accommodation_id < 13 && (
                                        <>
                                          <House className="icon" />
                                        </>
                                      )}
                                      {reservation?.accommodation_id === 13 && (
                                        <>
                                          <Tent className="icon" />
                                        </>
                                      )}
                                      {reservation?.accommodation_id === 14 && (
                                        <>
                                          <Trailer className="icon" />
                                        </>
                                      )}
                                    </div>

                                    <div className="reservation-preview-single-head-left-data">
                                      <h4 className="reservation-preview-single-name">
                                        {reservation?.name}
                                      </h4>
                                      <h5 className="reservation-preview-single-place">
                                        {reservation?.accommodation_id < 13 && (
                                          <>{`${reservation?.accommodation_id}. mājiņa`}</>
                                        )}
                                        {reservation?.accommodation_id ===
                                          13 && <>Telts vieta</>}
                                        {reservation?.accommodation_id ===
                                          14 && <>Treilera vieta</>}

                                        {calculateStayDuration(
                                          reservation?.check_in_date,
                                          reservation?.check_out_date
                                        )}
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="reservation-preview-single-head-right">
                                    <Link
                                      to={`tel:${reservation?.phone_number}`}
                                      className="reservation-preview-single-head-left-icon"
                                    >
                                      <PhoneCall className="icon" />
                                    </Link>
                                  </div>
                                </div>

                                <div className="reservation-preview-single-comment">
                                  {reservation?.notes?.length > 0 ? (
                                    <>
                                      <p>{reservation?.notes}</p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="no-comment">
                                        Nav atstāts komentārs
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                        {dataDashboard?.leaving?.length >
                          reservationsToShow && (
                          <>
                            <p className="reservation-preview-more">
                              +{" "}
                              {dataDashboard?.leaving?.length -
                                reservationsToShow}{" "}
                              rezervācija/-s
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="reservation-preview-none">
                          <p>Nav atrastu pozīciju</p>
                        </div>
                      </>
                    )}
                  </Leaving>
                </ReservationPreview>

                <Widgets>
                  <div className="widget">
                    <div className="widget-icon">
                      <div className="widget-icon-wrapper">
                        <MoneyEuroCircle className="icon" />
                      </div>
                      <h3 className="widget-name">Ienākumi</h3>
                    </div>
                    <p className="widget-count">
                      {dataDashboard?.earnings
                        ? `€${dataDashboard?.earnings}`
                        : `€0`}
                    </p>
                    <p className="widget-desc">
                      Kopējie ienākumi - noslēgtās rezervācijas
                    </p>
                  </div>

                  <div className="widget">
                    <div className="widget-icon">
                      <div className="widget-icon-wrapper">
                        <OpenBook className="icon" />
                      </div>
                      <h3 className="widget-name">Rezervācijas</h3>
                    </div>

                    <p className="widget-count">
                      {dataDashboard?.reservations - 3}
                    </p>
                    <p className="widget-desc">
                      Aktīvās un neapstiprinātās rezervācijas
                    </p>
                  </div>

                  <div className="widget">
                    <div className="widget-icon">
                      <div className="widget-icon-wrapper">
                        <Person className="icon" />
                      </div>
                      <h3 className="widget-name">Personas kempingā</h3>
                    </div>
                    <p className="widget-count">
                      {dataDashboard?.personsInCampsite}
                    </p>
                    <p className="widget-desc">
                      Aktīvo rezervāciju personu kopskaits - šodien
                    </p>
                  </div>

                  <div className="widget">
                    <div className="widget-icon">
                      <div className="widget-icon-wrapper">
                        <Tent className="icon" />
                      </div>
                      <h3 className="widget-name">Telšu vietas</h3>
                    </div>
                    <p className="widget-count">
                      {dataDashboard?.tentSpaceUsageToday}/40
                    </p>
                    <p className="widget-desc">
                      šodien ({dateShort !== null && dateShort})
                    </p>
                  </div>

                  <div className="widget">
                    <div className="widget-icon">
                      <div className="widget-icon-wrapper">
                        <Trailer className="icon" />
                      </div>
                      <h3 className="widget-name">Treileru vietas</h3>
                    </div>
                    <p className="widget-count">
                      {dataDashboard?.trailerUsageToday}/20
                    </p>
                    <p className="widget-desc">
                      šodien ({dateShort !== null && dateShort})
                    </p>
                  </div>

                  <div className="widget">
                    <div className="widget-icon">
                      <div className="widget-icon-wrapper">
                        <House className="icon" />
                      </div>
                      <h3 className="widget-name">Mājiņu aizņemtība</h3>
                    </div>
                    <p className="widget-count">
                      {dataDashboard?.housesUsageToday}/12
                    </p>
                    <p className="widget-desc">
                      šodien ({dateShort !== null && dateShort})
                    </p>
                  </div>
                </Widgets>

                <Statistics>
                  <div className="statistics-wrapper">
                    {statisticsSelected === "Revenue" ? (
                      <>
                        <h3>Ienākumi (eiro)</h3>
                        <ResponsiveContainer width={"100%"} height={500}>
                          <BarChart
                            data={reservationRevenue}
                            margin={{
                              top: 40,
                              bottom: 20,
                              right: 20,
                              left: 20,
                            }}
                          >
                            <CartesianGrid
                              strokeDasharray="3 3"
                              stroke="#ccc"
                            />
                            <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                            <YAxis tick={{ fontSize: 12 }} />
                            <Tooltip
                              contentStyle={{
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                                borderRadius: "5px",
                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                              }}
                            />

                            <Bar
                              dataKey="mājiņas"
                              stackId="a"
                              fill="var(--green)"
                              radius={[5, 5, 0, 0]}
                              stroke="#fff"
                              strokeWidth={1}
                            />
                            <Bar
                              dataKey="teltis"
                              stackId="a"
                              fill="var(--grey)"
                              radius={[5, 5, 0, 0]}
                              stroke="#fff"
                              strokeWidth={1}
                            />
                            <Bar
                              dataKey="treileri"
                              stackId="a"
                              fill="#A1B285"
                              radius={[5, 5, 0, 0]}
                              stroke="#fff"
                              strokeWidth={1}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                    ) : (
                      <>
                        <h3>Rezervācijas (kopskaits)</h3>
                        <ResponsiveContainer width="100%" height={500}>
                          <BarChart
                            data={reservationCount}
                            margin={{
                              top: 40,
                              bottom: 20,
                              right: 20,
                              left: 20,
                            }}
                          >
                            <CartesianGrid
                              strokeDasharray="3 3"
                              stroke="#ccc"
                            />
                            <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                            <YAxis tick={{ fontSize: 12 }} />
                            <Tooltip
                              contentStyle={{
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                                borderRadius: "5px",
                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                              }}
                            />
                            <Legend
                              verticalAlign="top"
                              height={36}
                              iconType="circle"
                              wrapperStyle={{
                                top: 0,
                                right: 0,
                                left: 0,
                                textAlign: "center",
                                fontSize: 14,
                              }}
                            />
                            <Bar
                              dataKey="mājiņas"
                              stackId="a"
                              fill="var(--green)"
                              radius={[5, 5, 0, 0]}
                              stroke="#fff"
                              strokeWidth={1}
                            />
                            <Bar
                              dataKey="teltis"
                              stackId="a"
                              fill="var(--grey)"
                              radius={[5, 5, 0, 0]}
                              stroke="#fff"
                              strokeWidth={1}
                            />
                            <Bar
                              dataKey="treileri"
                              stackId="a"
                              fill="#A1B285"
                              radius={[5, 5, 0, 0]}
                              stroke="#fff"
                              strokeWidth={1}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                    )}
                  </div>

                  <div className="statistics-filters">
                    <div
                      className="statistics-filter"
                      onClick={() => setStatisticsSelected("Revenue")}
                      style={{
                        borderColor:
                          statisticsSelected === "Revenue"
                            ? "var(--green)"
                            : "var(--lightgrey)",
                      }}
                    >
                      <div className="statistics-filter-left">
                        <div className="statistics-filter-icon-wrapper">
                          <MoneyEuroCircle className="icon" />
                        </div>

                        <div className="statistics-filter-text">
                          <h3>Ienākumi</h3>
                          <p>Apskatīt kempinga ienākumu grafiku</p>
                        </div>
                      </div>

                      <ArrowRightShort className="icon-arrow" />
                    </div>

                    <div
                      className="statistics-filter"
                      onClick={() => setStatisticsSelected("Reservations")}
                      style={{
                        borderColor:
                          statisticsSelected === "Reservations"
                            ? "var(--green)"
                            : "var(--lightgrey)",
                      }}
                    >
                      <div className="statistics-filter-left">
                        <div className="statistics-filter-icon-wrapper">
                          <OpenBook className="icon" />
                        </div>

                        <div className="statistics-filter-text">
                          <h3>Rezervācijas</h3>
                          <p>Apskatīt kempinga kopēju rezervācijas grafiku</p>
                        </div>
                      </div>

                      <ArrowRightShort className="icon-arrow" />
                    </div>
                  </div>
                </Statistics>
              </div>
              <Footer />
            </Container>
          </motion.div>
        </>
      )}
    </Wrapper>
  );
};

const Statistics = styled.div`
  margin: 20px 0 20px;
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 765px) {
    flex-direction: column-reverse;
  }

  .statistics-wrapper {
    flex-basis: 70%;
    background-color: var(--dashbox);
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
    border: 1px solid var(--lightgrey);

    h3 {
      font-size: 1rem;
    }

    .recharts-legend-wrapper {
      margin: 25px 0 0 50px;
    }

    .recharts-wrapper {
      margin-left: -30px;
    }
  }

  .statistics-filters {
    flex-grow: 1;

    .statistics-filter:hover {
      border-color: var(--green);
    }

    .statistics-filter {
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      padding: 20px;
      margin: 0 0 20px 0;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-start;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13),
        0 1px 2px 0 rgba(0, 0, 0, 0.11);
      border: 1px solid var(--lightgrey);
      cursor: pointer;
      background-color: var(--dashbox);

      .statistics-filter-left {
        display: flex;
        gap: 10px;

        .statistics-filter-icon-wrapper {
          height: 32px;
          width: 100%;
          max-width: 32px;
          border-radius: 5px;
          background-color: var(--lightgreen);
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            max-width: 22px;
            min-width: 22px;
            width: 100%;
            color: var(--green);
          }
        }

        .statistics-filter-text {
          h3 {
            font-size: 1.05rem;
            margin: 0 0 5px 0;
          }

          p {
            font-size: 0.85rem;
            color: var(--grey);
          }
        }
      }

      .icon-arrow {
        max-width: 32px;
        min-width: 32px;
        width: 100%;
        color: var(--green);
      }
    }
  }
`;

const Leaving = styled.div`
  flex-grow: 1;
  max-width: 50%;
  width: 100%;

  @media only screen and (max-width: 765px) {
    max-width: unset;
  }
`;

const Arrivals = styled.div`
  flex-grow: 1;
  max-width: 50%;
  width: 100%;

  @media only screen and (max-width: 765px) {
    max-width: unset;
  }
`;

const ReservationPreview = styled.div`
  margin: 15px 0 0 0;
  background-color: var(--green);
  padding: 20px;
  color: var(--white);
  border-radius: 5px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 765px) {
    flex-direction: column;
  }

  .reservation-preview-more {
    font-size: 0.9rem;
    text-align: center;
    margin: 15px 0 0 0;
  }

  .reservation-preview-none {
    text-align: center;
    margin: 10px 0;

    p {
      font-size: 0.9rem;
      color: var(--grey);
    }
  }

  .reservation-preview {
    margin: 10px 0 0 0;

    .reservation-preview-single {
      background-color: var(--lightgreen);
      color: var(--black);
      border-radius: 5px;
      padding: 20px;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13),
        0 1px 2px 0 rgba(0, 0, 0, 0.11);

      .reservation-preview-single-head {
        display: flex;
        justify-content: space-between;
        margin: 0 0 10px 0;
        gap: 15px;

        .reservation-preview-single-head-left-icon {
          height: 32px;
          width: 100%;
          max-width: 32px;
          border-radius: 5px;
          background-color: var(--green);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;

          .icon {
            color: var(--white);
            max-width: 22px;
            min-width: 22px;
            width: 100%;
          }
        }

        .reservation-preview-single-head-left {
          display: flex;
          gap: 15px;

          .reservation-preview-single-head-left-data {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .reservation-preview-single-name {
              font-size: 1rem;
            }

            .reservation-preview-single-place {
              font-size: 0.85rem;
            }
          }
        }

        .reservation-preview-single-head-right {
          a {
            cursor: pointer;
          }
        }
      }

      .reservation-preview-single-comment {
        p {
          font-size: 0.85rem;
          line-height: 1.4;
        }

        .no-comment {
          color: var(--grey);
        }
      }
    }
  }

  .widget-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    .widget-name {
      font-size: 1.05rem;
    }

    a {
      color: var(--white);
      font-size: 0.9rem;
    }
  }

  .widget-icon {
    display: flex;
    gap: 10px;
    align-items: center;
    z-index: 20;

    .widget-icon-wrapper {
      height: 32px;
      width: 100%;
      max-width: 32px;
      border-radius: 5px;
      background-color: var(--lightgreen);
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        max-width: 22px;
        min-width: 22px;
        width: 100%;
        color: var(--green);
      }
    }
  }
`;

const Widgets = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  .widget {
    background-color: var(--dashbox);
    border-radius: 5px;
    padding: 20px;
    flex-basis: 30%;
    flex-grow: 1;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
    border: 1px solid var(--lightgrey);
    position: relative;

    @media only screen and (max-width: 765px) {
      flex-basis: 45%;
    }

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
    }

    .widget-icon {
      display: flex;
      gap: 10px;
      align-items: center;
      z-index: 20;

      .widget-icon-wrapper {
        height: 32px;
        width: 100%;
        max-width: 32px;
        border-radius: 5px;
        background-color: var(--lightgreen);
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          max-width: 22px;
          min-width: 22px;
          width: 100%;
          color: var(--green);
        }
      }
    }

    .widget-name {
      font-size: 1.05rem;
      z-index: 20;
    }

    .widget-count {
      margin: 20px 0 10px 0;
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--green);
      z-index: 20;
    }

    .widget-desc {
      font-size: 0.85rem;
      color: var(--grey);
      z-index: 80;

      span {
        font-weight: 600;
        color: var(--grey);
      }

      .icon {
        max-width: 24px;
        width: 100%;
        margin: 0 10px 0 0;
      }
    }
  }
`;

const RouterAndTime = styled.div`
  border-bottom: 1px solid var(--lightgrey);
  background-color: var(--dashback);

  .router-time-container {
    padding: 10px 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1250px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 760px) {
    top: 86px;
  }

  .heading-date {
    p {
      font-size: 0.9rem;
      color: var(--green);
      font-weight: 600;
      text-align: end;
    }
  }

  .heading-router {
    display: flex;
    gap: 5px;
    align-items: center;

    p {
      font-size: 0.9rem;
      color: var(--grey);
    }

    a {
      font-size: 0.9rem;
      color: var(--green);
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const Container = styled.div`
  flex: 1;
  padding-bottom: 200px;

  .panel {
    padding: 20px;
    max-width: 1250px;
    margin: 0 auto;

    h3 {
      font-size: 1.2rem;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: var(--dashback);
`;

export default Dashboard;
