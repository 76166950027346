import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//components
import PageHeading from "../components/PageHeading";

//translation
import { useTranslation } from "react-i18next";

const CampHouses = () => {
  const { t, i18n } = useTranslation();

  const filterOptions = [
    { name: t("houses_filter_p1"), selected: false, persons: "All" },
    { name: `2 ${t("houses_filter_p2")}`, selected: false, persons: 2 },
    { name: `3 ${t("houses_filter_p2")}`, selected: false, persons: 3 },
    { name: `4 ${t("houses_filter_p2")}`, selected: false, persons: 4 },
    { name: `6 ${t("houses_filter_p2")}`, selected: false, persons: 6 },
  ];

  const [filter, setFilter] = useState(filterOptions);

  const handleFilterOptions = (index) => {
    const updatedFilter = [...filterOptions];
    updatedFilter[index].selected = true;
    setFilter(updatedFilter);

    const selectedPersons = filterOptions[index].persons;
    if (selectedPersons === "All") {
      setHousesState(houses);
    } else {
      const filteredHouses = houses.filter(
        (house) => house.persons === selectedPersons
      );
      setHousesState(filteredHouses);
    }
  };

  const houses = [
    {
      name: t("house_1_info_name"),
      desc: t("house_1_info_desc"),
      images: [
        {
          url: "../images/houses/1/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/1/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 45,
      persons: 4,
      link: "/houses/1",
    },
    {
      name: t("house_2_info_name"),
      desc: t("house_2_info_desc"),
      images: [
        {
          url: "../images/houses/2/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/2/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/2",
    },
    {
      name: t("house_3_info_name"),
      desc: t("house_3_info_desc"),
      images: [
        {
          url: "../images/houses/3/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/3/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/3",
    },
    {
      name: t("house_4_info_name"),
      desc: t("house_4_info_desc"),
      images: [
        {
          url: "../images/houses/4/0.jpg",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/4/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/4",
    },
    {
      name: t("house_5_info_name"),
      desc: t("house_5_info_desc"),
      images: [
        {
          url: "../images/houses/5/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/5/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/5",
    },
    {
      name: t("house_6_info_name"),
      desc: t("house_6_info_desc"),
      images: [
        {
          url: "../images/houses/6/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/6/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 40,
      persons: 3,
      link: "/houses/6",
    },
    {
      name: t("house_7_info_name"),
      desc: t("house_7_info_desc"),
      images: [
        {
          url: "../images/houses/7/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/7/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 40,
      persons: 3,
      link: "/houses/7",
    },
    {
      name: t("house_8_info_name"),
      desc: t("house_8_info_desc"),
      images: [
        {
          url: "../images/houses/8/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/8/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 40,
      persons: 3,
      link: "/houses/8",
    },
    {
      name: t("house_9_info_name"),
      desc: t("house_9_info_desc"),
      images: [
        {
          url: "../images/houses/9/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/9/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/9",
    },
    {
      name: t("house_10_info_name"),
      desc: t("house_10_info_desc"),
      images: [
        {
          url: "../images/houses/10/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/10/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 70,
      persons: 6,
      link: "/houses/10",
    },
    {
      name: t("house_11_info_name"),
      desc: t("house_11_info_desc"),
      images: [
        {
          url: "../images/houses/11/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/11/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/11",
    },
    {
      name: t("house_12_info_name"),
      desc: t("house_12_info_desc"),
      images: [
        {
          url: "../images/houses/12/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/12/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/12",
    },
  ];

  const [housesState, setHousesState] = useState(houses);

  useEffect(() => {
    handleFilterOptions(0);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
      setHousesState(houses);
    } else {
      i18n.changeLanguage(lang);
      setHousesState(houses);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("houses_title")}</title>
      </Helmet>
      <PageHeading
        image={"images/page-headings/houses.png"}
        imagePosition={"40% 50%"}
        text={t("houses_heading")}
      />

      <div className="heading">
        <h3>{t("houses_h3")}</h3>
      </div>

      <div className="filter-options-wrapper">
        {filter?.map((option, i) => (
          <div
            key={i}
            className="filter-option-item"
            style={{
              backgroundColor: option?.selected
                ? "var(--green)"
                : "var(--white)",
            }}
            onClick={() => handleFilterOptions(i)}
          >
            <p
              style={{
                color: option?.selected ? "var(--white)" : "var(--green)",
              }}
            >
              {option.name}
            </p>
          </div>
        ))}
      </div>

      <div className="houses-wrapper">
        {housesState?.map((house) => (
          <Link
            key={house?.name}
            to={house?.link}
            onClick={() => window.scrollTo(0, 0)}
          >
            <div key={house?.name} className="house-single">
              <div className="house-single-img-wrapper">
                {house?.images?.map((image) => (
                  <img
                    className={image?.class}
                    src={image?.url}
                    // alt="house-img"
                  />
                ))}
              </div>

              <div className="house-single-data">
                <h3 className="house-single-data-name">{house?.name}</h3>
                <p className="house-single-data-desc">{house?.desc}</p>
                <p className="house-single-data-price">
                  €{house?.price}
                  <span> / {t("houses_p1")}</span>
                </p>
              </div>

              <div>
                <div to={house?.link} className="house-single-reserve">
                  {t("houses_p2")}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 40px 0;

  .houses-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    padding: 0 20px;
    justify-content: flex-start;

    a {
      text-decoration: none;
      color: var(--black);
      cursor: pointer;
      display: flex;
      flex-direction: column;

      max-width: calc((100% - 3% * 3) / 4);
      min-width: calc((100% - 3% * 3) / 4);

      @media only screen and (max-width: 900px) {
        max-width: calc((100% - 3% * 2) / 3); /* For three houses in one line */
        min-width: calc((100% - 3% * 2) / 3);
      }

      @media only screen and (max-width: 700px) {
        max-width: calc((100% - 3% * 1) / 2); /* For two houses in one line */
        min-width: calc((100% - 3% * 1) / 2);
      }

      @media only screen and (max-width: 600px) {
        max-width: 100%; /* For one house in one line (full width) */
        min-width: 100%;
      }
    }

    @media only screen and (min-width: 700px) {
      .house-single:hover {
        .house-single-reserve {
          color: var(--white);
          background-color: var(--green);
        }

        .house-single-img-placeholder-second {
          display: block !important;
        }

        img {
          transform: scale(1.1);
        }
      }
    }

    .house-single {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      margin: 0 0 40px 0;
      flex: 1;
      justify-content: space-between;

      .house-single-reserve {
        border: 1px solid var(--green);
        padding: 8px 15px;
        max-width: unset;
        width: 100%;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        transition: 0.2s linear;
        font-size: 0.9rem;
        color: var(--green);
      }

      .house-single-data {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .house-single-data-name {
          font-size: 1.2rem;
        }

        .house-single-data-desc {
          font-size: 0.9rem;
          font-weight: 400;
        }

        .house-single-data-price {
          font-size: 1.2rem;
          font-weight: 700;
          color: var(--green);

          span {
            font-weight: 400;
            font-size: 0.9rem;
            color: var(--grey);
          }
        }
      }

      .house-single-img-wrapper {
        width: 100%;
        height: 100%;
        max-height: 185px;
        min-height: 185px;
        border-radius: 5px;
        position: relative;
        overflow: hidden;

        @media only screen and (max-width: 600px) {
          max-height: 225px;
          min-height: 225px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
          transition: 0.2s linear;
        }

        .house-single-img-placeholder-second {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .filter-options-wrapper {
    padding: 0 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin: 10px 0 40px;

    .filter-option-item {
      padding: 8px 15px;
      border-radius: 5px;
      border: 1px solid var(--green);
      cursor: pointer;
      transition: 0.2s linear;

      p {
        font-size: 0.9rem;
        color: var(--green);
        transition: 0.2s linear;
      }
    }

    .filter-option-item:hover {
      background-color: var(--green);

      p {
        color: var(--white);
      }
    }
  }

  .heading {
    padding: 20px 20px 10px 20px;
    margin: 10px 0 0 0;

    h3 {
      font-size: 1.2rem;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
`;

export default CampHouses;
