import React from "react";
import styled from "styled-components";

const PageHeading = ({ image, imagePosition, text }) => {
  return (
    <Wrapper>
      <img src={image} alt="heading-img" style={{objectPosition: imagePosition}} />
      <h1>{text}</h1>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  height: 230px;
  margin: 20px auto;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1240px) {
    margin: 0 auto;
    max-width: unset;
    border-radius: 0 !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    background-color: var(--whitesmoke);

    @media only screen and (max-width: 1240px) {
      border-radius: 0 !important;
    }
  }

  h1 {
    position: absolute;
    color: white;
    font-weight: 600;
    font-size: 1.6rem;
    margin: 0 20px;
    text-align: center;
    text-shadow: 1px 1px 2px var(--black);
  }
`;

export default PageHeading;
