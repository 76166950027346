import "./App.css";

//router
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

//components
import Header from "./main/components/Header";
import Footer from "./main/components/Footer";
import CookiesAlert from "./main/components/CookiesAlert";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//pages
import Home from "./main/pages/Home";
import Pricing from "./main/pages/Pricing";
import CampHouses from "./main/pages/CampHouses";
import CampHouse from "./main/pages/CampHouse";
import Tents from "./main/pages/Tents";
import Trailers from "./main/pages/Trailers";
import Rules from "./main/pages/Rules";
import PrivacyPolicy from "./main/pages/PrivacyPolicy";
import Gallery from "./main/pages/Gallery";
import SearchAvailability from "./main/pages/SearchAvailability";
import Map from "./main/pages/Map";

//pages--admin
import Dashboard from "./admin/pages/Dashboard";
import Login from "./admin/pages/Login";
import Calendar from "./admin/pages/Calendar";
import Settings from "./admin/pages/Settings";
import Notifications from "./admin/pages/Notifications";

//additionals
import { Helmet } from "react-helmet";

//translation
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";

//google
import ReactGA from "react-ga4";
ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);

function App() {
  const CustomNavigate = ({ to, beforeNavigate }) => {
    // Execute the beforeNavigate function before navigating
    beforeNavigate && beforeNavigate();

    // Render the Navigate component with the specified "to" prop
    return <Navigate to={to} />;
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://plaucaki.lv/" />
        <meta
          property="og:title"
          content="Kempings Plaucaki - Pūrciems, Latvija"
        />
        <meta name="author" content="Ansis Arturs Irbe" />
        <meta
          name="description"
          content="Atveries dabas skaistumam un izbaudi pilnīgu miera un saskaņas sajūtu tikai dažu soļu attālumā no jūras. Mūsu kempings ir paradīze tiem, kas vēlas izvairīties no ikdienas steigas un iegrimt dabas klusumā."
        />
        <meta
          property="og:description"
          content="Atveries dabas skaistumam un izbaudi pilnīgu miera un saskaņas sajūtu tikai dažu soļu attālumā no jūras. Mūsu kempings ir paradīze tiem, kas vēlas izvairīties no ikdienas steigas un iegrimt dabas klusumā."
        />
        <meta property="og:locale" content="lv_LV" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://plaucaki.lv/" />
        <meta
          property="og:site_name"
          content="Kempings Plaucaki - Pūrciems, Latvija"
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/images/logo-camp.png"
        />
        <meta
          name="twitter:description"
          content="Atveries dabas skaistumam un izbaudi pilnīgu miera un saskaņas sajūtu tikai dažu soļu attālumā no jūras. Mūsu kempings ir paradīze tiem, kas vēlas izvairīties no ikdienas steigas un iegrimt dabas klusumā."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Kempings Plaucaki - Pūrciems, Latvija"
        />
        <meta
          name="twitter:image"
          content="https://plaucaki.lv/images/logo-camp.png"
        />
        <meta
          name="keywords"
          content="kempings Latvijā, Plaucaki kempings, Baltā kāpa, romantiskas koka mājiņas, telšu vietas, treileru vietas, jūras tuvums, dabas skaistums, miera sajūta, saskaņa ar dabu, ainaviskās takas, ugunskura vietas, elektrības pieslēgums treileriem, pastaigas Baltā kāpa, dabas klusums, atpūta pie jūras, ģimenes atpūta, dabas izpēte, kempinga mājiņas, atpūtas vieta, ūdens uzpildes vieta, kempinga teritorija, Purciems, Roja, Kurzeme"
        />
      </Helmet>

      <Routes>
        <Route
          path="/"
          element={
            <div className="container">
              <Header />
              <Outlet />
              <Footer />
            </div>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/map" element={<Map />} />
          <Route path="/houses" element={<CampHouses />} />
          <Route path="/houses/:id" element={<CampHouse />} />
          <Route path="/tents" element={<Tents />} />
          <Route path="/trailers" element={<Trailers />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/reserve" element={<SearchAvailability />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route
            path="/lv"
            element={
              <CustomNavigate
                to="/"
                beforeNavigate={() => localStorage.setItem("lang", "latvian")}
              />
            }
          />
          <Route
            path="/en"
            element={
              <CustomNavigate
                to="/"
                beforeNavigate={() => localStorage.setItem("lang", "english")}
              />
            }
          />
          <Route
            path="/ru"
            element={
              <CustomNavigate
                to="/"
                beforeNavigate={() => localStorage.setItem("lang", "russian")}
              />
            }
          />
        </Route>
        <Route path="/admin" exact={true} element={<Dashboard />} />
        <Route path="/admin/calendar" element={<Calendar />} />
        <Route path="/admin/settings" element={<Settings />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/notifications" element={<Notifications />} />
      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Flip}
      />
      <CookiesAlert />
    </I18nextProvider>
  );
}

export default App;
