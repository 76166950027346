import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";

import { NavLink, Link } from "react-router-dom";

// import { getTemperaturesUpdated, getHomepageStatusApi } from "../../api/api-list";

//icons
import { Home } from "@styled-icons/boxicons-regular/Home";
import { CalendarWeek } from "@styled-icons/bootstrap/CalendarWeek";
import { NotificationsNone } from "@styled-icons/material/NotificationsNone";
import { Settings2Outline } from "@styled-icons/evaicons-outline/Settings2Outline";

//api
import { getNotificationsApi } from "../../api/api-list";

const Header = () => {
  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    const apiUrl = `${getNotificationsApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        // console.log(response.data);

        const data = response.data;

        const addThreeHours = (reservations) => {
          if (reservations.length === 0 || reservations[0].length === 0) {
            return []; // Return empty array if reservations or reservations[0] is empty
          }

          return reservations.map((reservation) => ({
            ...reservation,
            created_at: moment(reservation.created_at)
              .add(3, "hours")
              .format("YYYY-MM-DD HH:mm:ss"),
          }));
        };

        if (data && data.length > 0) {
          const updatedNotifications = addThreeHours(data);
          setNotifications(updatedNotifications);
        } else {
          setNotifications([]);
        }

        // console.log(updatedNotifications);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Wrapper>
      <NavLink to="/admin" end>
        <Home className="icon" />
        <p>Panelis</p>
      </NavLink>

      <NavLink to="/admin/calendar">
        <CalendarWeek className="icon" />
        <p>Kalendārs</p>
      </NavLink>

      <NavLink to="/admin/notifications">
        <NotificationsNone className="icon" />
        <p>Paziņojumi</p>
        {notifications?.length > 0 && (
          <>
            <div className="notifications-alert" />
          </>
        )}
      </NavLink>

      <NavLink to="/admin/settings">
        <Settings2Outline className="icon" />
        <p>Iestatījumi</p>
      </NavLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 120;
  background-color: var(--dashback);
  display: none;
  /* justify-content: space-between; */
  padding: 10px 10px 20px 10px;

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    border-radius: 5px;
    flex-grow: 1;
    position: relative;

    .notifications-alert {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--green);
      position: absolute;
      right: 25px;
      top: 10px;
    }

    p {
      color: var(--green);
      font-size: 0.85rem;
      margin: 10px 0 0 0;
    }

    .icon {
      max-width: 24px;
      width: 100%;
      color: var(--green);
    }
  }

  .active {
    background-color: var(--lightgreen);

    p {
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 650px) {
    display: flex;
  }
`;

export default Header;
