import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

//icons
import { Minus } from "@styled-icons/boxicons-regular/Minus";
import { Plus } from "@styled-icons/boxicons-regular/Plus";
import { CheckmarkCircleOutline } from "@styled-icons/evaicons-outline/CheckmarkCircleOutline";
import { DoorClosed } from "@styled-icons/bootstrap/DoorClosed";

//components
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { lv } from "date-fns/locale";
import PuffLoader from "react-spinners/PuffLoader";
import { format } from "date-fns";

//api
import {
  getAccommodationBookings,
  createReservation,
  createNotificationApi,
  getHomepageStatusApi,
  sendReservationConfirmationApi,
} from "../../api/api-list";

//manually disabled dates used when all the campsite is closed
import { manualDisabledDates } from "../../api/manual-disabled-dates";

//translation
import { useTranslation } from "react-i18next";

const Tents = () => {
  const { t, i18n } = useTranslation();

  const id = 13;

  const propertyImages = [
    {
      image: "../images/tents/0.png",
      active: true,
    },
    {
      image: "../images/tents/1.png",
      active: false,
    },
    {
      image: "../images/tents/2.png",
      active: false,
    },
    {
      image: "../images/tents/3.png",
      active: false,
    },
  ];

  const [mainImage, setMainImage] = useState(propertyImages[0]?.image);
  const [allimages, setAllImages] = useState(propertyImages);

  const handleImagePlaceholder = (index) => {
    setMainImage(propertyImages[index]?.image);

    const updatedImages = propertyImages.map((img, idx) => {
      if (idx === index) {
        return { ...img, active: true };
      } else {
        return { ...img, active: false };
      }
    });

    setAllImages(updatedImages);
  };

  const [personCount, setPersonCount] = useState(1);
  const [maxPersons, setMaxPersons] = useState(2);

  const handlePersonCount = (action) => {
    if (action === "add") {
      if (personCount < maxPersons) {
        setPersonCount((personCount) => personCount + 1);
      }
    } else if (action === "remove") {
      if (personCount <= maxPersons && personCount > 1) {
        setPersonCount((personCount) => personCount - 1);
      }
    }
  };

  //booking dates
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  //pricing
  const [selectedDatesCount, setSelectedDatesCount] = useState(0);
  const [price] = useState(10);
  const [totalPrice, setTotalPrice] = useState(0);

  const calculateSelectedDatesCount = () => {
    const millisecondsInDay = 86400000; // Number of milliseconds in a day
    const daysDifference = (endDate - startDate) / millisecondsInDay;
    const newSelectedDatesCount = Math.abs(daysDifference); // Adding 1 to include both start and end dates
    setSelectedDatesCount(newSelectedDatesCount);
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  //rules checkbox
  const [isChekedRules, setIsChekedRules] = useState(false);

  //bookings
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reservationCreated, setReservationCreated] = useState(false);

  //create a new booking
  const handleReservationSubmit = (e) => {
    e.preventDefault();

    if (startDate.getTime() === endDate.getTime()) {
      alert("Iebraukšanas un izbraukšanas datums nevar būt vienāds.");
      return false;
    }

    setLoading(true);

    //navigate to the reservation div top
    const reserveElement = document.getElementById("reservation-tent");
    if (reserveElement) {
      const offset =
        reserveElement.getBoundingClientRect().top + window.scrollY - 120;
      window.scrollTo({ top: offset, behavior: "smooth" });
    }

    const formatDate = (date) => {
      return format(date, "yyyy-MM-dd");
    };

    // Gather form data
    const formData = {
      accommodationId: parseInt(id), // Assuming `id` is the ID of the selected accommodation
      persons: personCount,
      checkInDate: formatDate(startDate),
      checkOutDate: formatDate(endDate),
      status: "Pending", // Default status for new reservations
      totalPrice: totalPrice,
      name: e.target.name.value,
      email: e.target.email.value,
      phoneNumber: e.target.number.value,
      notes: e.target.message.value,
    };

    const formDataNotification = {
      name: e.target.name.value,
      message: `Pievienoja jaunu rezervāciju - telts vieta, no ${formatDate(
        startDate
      )} līdz ${formatDate(endDate)}`,
    };

    const createNotification = () => {
      axios.post(createNotificationApi, formDataNotification, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      });
    };

    const sendReservationConfirmation = () => {
      axios.post(sendReservationConfirmationApi, formData, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      });
    };

    // Send reservation data to backend API
    axios
      .post(createReservation, formData, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Handle successful reservation creation
        createNotification();
        sendReservationConfirmation();

        setTimeout(() => {
          setReservationCreated(true);
          setLoading(false);
        }, 3000);
      });
  };

  //get booking data
  const getBookingData = () => {
    const apiUrl = `${getAccommodationBookings}${id}`; // Replace with your actual API endpoint URL

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        const reservations = response.data;

        if (reservations?.length > 0) {
          // Count reservations for each date
          const reservationsCount = {};
          reservations.forEach((booking) => {
            const startDate = new Date(booking.check_in_date)
              .toISOString()
              .slice(0, 10);
            const endDate = new Date(booking.check_out_date)
              .toISOString()
              .slice(0, 10);

            let currentDate = new Date(startDate);
            while (currentDate < new Date(endDate)) {
              const dateString = currentDate.toISOString().slice(0, 10);
              reservationsCount[dateString] =
                (reservationsCount[dateString] || 0) + 1;
              currentDate.setDate(currentDate.getDate() + 1);
            }
          });

          // Find dates with maximum reservations
          const threshold = 40; // Adjust this threshold as needed
          const fullyBookedDates = Object.keys(reservationsCount).filter(
            (date) => reservationsCount[date] >= threshold
          );

          fullyBookedDates.push(...manualDisabledDates);

          setBookings(fullyBookedDates);
        }
      });
    // .catch((error) => {
    //   // If an error occurs, set the error state with the error message
    //   console.log(error);
    // });
  };

  const [homepageStatus, setHomepageStatus] = useState(true);

  const getHomepageStatus = () => {
    const apiUrl = `${getHomepageStatusApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        const status = response?.data?.homepage_status > 0 ? true : false;
        setHomepageStatus(status);
      });
  };

  useEffect(() => {
    getHomepageStatus();
  }, []);

  useEffect(() => {
    if (homepageStatus === true) {
      setMaxPersons(10);
      getBookingData();
    }
  }, [homepageStatus]);

  useEffect(() => {
    calculateSelectedDatesCount();

    //eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    setTotalPrice((totalPrice) => selectedDatesCount * price);

    //eslint-disable-next-line
  }, [selectedDatesCount]);

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("tent_title")}</title>
      </Helmet>
      <div className="navigator">
        <Link to="/" className="previous-link">
          {t("tent_link_1")}
        </Link>

        <div className="divider">/</div>

        <p className="selected-link">{t("tent_link_2")}</p>
      </div>

      <div className="data-name">
        <h1>{t("tent_link_2")}</h1>
        <p>{t("tent_data_name_p")}</p>
      </div>

      <div className="property">
        <div className="property-data">
          <div className="property-main-image">
            <img src={mainImage} alt="property-img" />
          </div>

          <div className="property-images-list">
            {allimages?.map((image, i) => (
              <div
                key={image?.image}
                className="property-image"
                onClick={() => handleImagePlaceholder(i)}
                style={{
                  borderColor: image?.active ? "var(--green)" : "transparent",
                }}
              >
                <img src={image?.image} alt="property-img" />
              </div>
            ))}
          </div>

          <div className="property-description">
            <h3>{t("tent_h3")}</h3>
            <p>{t("tent_p1")}</p>

            <p>{t("tent_p2")}</p>

            <p>{t("tent_p3")}</p>

            <p>{t("tent_p4")}</p>

            <p>{t("tent_p5")}</p>

            <p>{t("tent_p6")}</p>
            <p>
              {t("tent_p7")} <span>14.00</span>, {t("tent_p8")}{" "}
              <span>12.00</span>
            </p>
          </div>
        </div>

        {homepageStatus ? (
          <>
            <form
              className="property-reservation"
              id="reservation-tent"
              onSubmit={handleReservationSubmit}
            >
              {loading ? (
                <LoadingWrapper>
                  <PuffLoader
                    color={"var(--green)"}
                    loading={loading}
                    size={32}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />

                  <p>{t("reservation_t1")}</p>
                </LoadingWrapper>
              ) : reservationCreated ? (
                <ReservationCreatedWrapper>
                  <CheckmarkCircleOutline className="icon" />
                  <h3>{t("reservation_t2")}</h3>
                  <p>{t("reservation_t3")}</p>
                  <p>{t("reservation_t4")}</p>
                </ReservationCreatedWrapper>
              ) : (
                <>
                  <div className="reservation-item">
                    <h3 className="reservation-heading">
                      {t("reservation_t5")}
                    </h3>
                    <div className="reservation-wrapper">
                      <div className="reservation-person-count">
                        <p>{personCount}</p>
                        <p>{t("reservation_t6")}</p>
                      </div>

                      <div className="reservation-person-count-btns">
                        <div
                          className="reservation-person-count-btn"
                          onClick={() => handlePersonCount("remove")}
                        >
                          <Minus className="icon" />
                        </div>

                        <div
                          className="reservation-person-count-btn"
                          onClick={() => handlePersonCount("add")}
                        >
                          <Plus className="icon" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="reservation-item">
                    <h3 className="reservation-heading">
                      {t("reservation_t7")}
                    </h3>
                    <div className="reservation-wrapper">
                      <input
                        type="text"
                        name="name"
                        placeholder={t("reservation_t8")}
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>

                  <div className="reservation-item">
                    <h3 className="reservation-heading">
                      {t("reservation_t9")}
                    </h3>
                    <div className="reservation-wrapper">
                      <DateRangePicker
                        ranges={[selectionRange]}
                        weekStartsOn={1}
                        showMonthAndYearPickers={false}
                        locale={lv}
                        rangeColors={["var(--green)"]}
                        minDate={new Date()}
                        onChange={handleSelect}
                        disabledDates={bookings}
                      />
                    </div>
                  </div>

                  <div className="reservation-item">
                    <h3 className="reservation-heading">
                      {t("reservation_t10")}
                    </h3>
                    <div className="reservation-wrapper">
                      <input
                        type="number"
                        name="number"
                        placeholder={t("reservation_t11")}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        maxLength={50}
                        required
                      />
                    </div>
                    <p className="reservation-comment">
                      {t("reservation_t12")}
                    </p>
                  </div>

                  <div className="reservation-item">
                    <h3 className="reservation-heading">
                      {t("reservation_t13")}
                    </h3>
                    <div className="reservation-wrapper">
                      <input
                        type="email"
                        name="email"
                        placeholder={t("reservation_t14")}
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>

                  <div className="reservation-item">
                    <h3 className="reservation-heading">
                      {t("reservation_t15")}
                    </h3>
                    <div className="reservation-wrapper">
                      <textarea
                        name="message"
                        placeholder={t("reservation_t16")}
                        maxLength={500}
                      ></textarea>
                    </div>
                  </div>

                  <div className="reservation-item" style={{ marginTop: 40 }}>
                    <div className="reservation-price-single">
                      <p>{t("reservation_t17")}</p>
                      <p>€{price}</p>
                    </div>
                    {totalPrice > 0 && <><div className="reservation-price-single">
                      <p>{t("reservation_t18")}</p>
                      <p>€{totalPrice}</p>
                    </div></>}
                  </div>
                  <div className="reservation-item">
                    <label className="checkmark-container">
                      <p>
                        {t("reservation_t19")}{" "}
                        <Link to="/rules" target="_blank">
                          {t("reservation_t20")}
                        </Link>
                      </p>
                      <input
                        onClick={() =>
                          setIsChekedRules((isChekedRules) => !isChekedRules)
                        }
                        type="checkbox"
                        checked={isChekedRules}
                        required
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="reservation-item">
                    <button type="submit" className="create-reservation-btn">
                      {t("reservation_t21")}
                    </button>
                  </div>
                </>
              )}
            </form>
          </>
        ) : (
          <CampsiteClosedAlert>
            <DoorClosed className="icon" />

            <p>{t("reservation_t22")}</p>
          </CampsiteClosedAlert>
        )}
      </div>
    </Wrapper>
  );
};

const CampsiteClosedAlert = styled.div`
  flex: 1;
  min-width: 250px;
  max-width: 450px;

  @media only screen and (max-width: 750px) {
    max-width: 750px;
  }

  padding: 100px 0 100px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  .icon {
    max-width: 24px;
    width: 100%;
    color: var(--red);
  }

  p {
    font-size: 0.9rem;
    text-align: center;
    line-height: 1.4;
  }
`;

const ReservationCreatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 20px 0;
  min-width: 300px;

  p {
    font-size: 1rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h3,
  p {
    text-align: center;
    color: var(--black);
    line-height: 1.4;
  }

  .icon {
    max-width: 32px;
    width: 100%;
    color: var(--green);
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  p {
    margin: 20px 0 0 0;
    font-weight: 1rem;
  }
`;

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 40px 20px;

  .property-reservation {
    flex: 1;
    max-width: 450px;

    @media only screen and (max-width: 750px) {
        max-width: 750px;
    }

    .reservation-item {
      flex: 1;
      margin-bottom: 20px;
      position: relative;

      .checkmark-container input:checked ~ .checkmark:after {
        display: block;
      }

      .checkmark-container input:checked ~ .checkmark {
        background-color: var(--green);
      }

      .checkmark-container:hover input ~ .checkmark {
        background-color: var(--green);
      }

      .checkmark-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: .9rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        p {
            font-size: .9rem;
            color: var(--black);
            line-height: 1.4;

            a {
                color: var(--black);
            }
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 5px;
            width: 7px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border-radius: 5px;
            background-color: var(--lightgrey);
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
      }

      .create-reservation-btn {
            cursor: pointer;
            color: var(--green);
            background-color: transparent;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 600;
            padding: 12px 15px;
            border-radius: 5px;
            outline: none;
            border: 1px solid var(--green);
            width: 100%;
            transition: .2s linear;
        }

        .create-reservation-btn:hover {
            color: var(--white);
            background-color: var(--green);
        }
      }

      .rdrDateRangePickerWrapper {
        width: 100%;

        .rdrDateDisplayWrapper {
          background-color: var(--lightgrey2);
        }

        .rdrDateDisplay {
          color: var(--green) !important;
        }

        .rdrCalendarWrapper {
          width: 100%;

          .rdrMonth {
            width: 100%;
          }
        }
      }

      .rdrDefinedRangesWrapper {
        display: none;
      }

      .reservation-comment {
        padding: 10px;
        color: var(--grey);
        font-size: 0.9rem;
      }

      .reservation-price-single {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        p {
          font-size: 1rem;
          font-weight: 700;
        }
      }

      .reservation-wrapper {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input,
        textarea {
          min-width: 100% !important;
          padding: 10px 15px;
          border: 1px solid var(--white);
          outline: none;
          font-family: Inter, sans-serif;
          font-weight: 400;
          font-size: 0.9rem;
          color: var(--black);
          border-radius: 5px;
          background-color: var(--lightgrey2);
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        textarea {
          resize: none;
          min-height: 150px;
        }

        input::placeholder,
        textarea::placeholder {
          color: var(--grey);
        }

        input:focus::placeholder,
        textarea:focus::placeholder {
          color: black; /* Change to the desired color when focused */
        }

        input,
        textarea {
          width: 100%;
        }

        .reservation-person-count {
          display: flex;
          gap: 5px;

          p {
            font-weight: 500;
            font-size: 1rem;
          }
        }

        .reservation-person-count-btns {
          display: flex;
          gap: 10px;

          .reservation-person-count-btn {
            padding: 5px;
            border-radius: 50%;
            background: var(--lightgrey2);
            display: flex;
            cursor: pointer;
            transition: 0.2s linear;

            .icon {
              max-width: 18px;
              width: 100%;
              color: var(--black);
            }
          }

          .reservation-person-count-btn:hover {
            background: var(--green);

            .icon {
              color: var(--white);
            }
          }
        }
      }

      .reservation-heading {
        font-weight: 500;
        font-size: 1rem;
        color: var(--green);
        margin: 0 0 10px 0;
      }
    }
  }


  .property {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @media only screen and (max-width: 750px) {
        flex-direction: column;
    }


    .property-data {
      .property-description {
        margin: 20px 0;
        max-width: 700px;

        h3 {
          color: var(--black);
          font-size: 1.2rem;
          margin: 25px 0;
        }

        p {
          color: var(--black);
          font-size: 1rem;
          line-height: 1.4;
          margin: 0 0 15px 0;
        }

        span {
          font-weight: 500;
        }
      }

      .property-images-list {
        display: flex;
        gap: 1%;
        margin: 5px 0;

        .property-image {
          max-width: 100px;
          height: 90px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid transparent;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        .property-image:hover {
          opacity: 0.9;
          cursor: pointer;
        }
      }

      .property-main-image {
        max-width: 800px;
        max-height: 550px;
        height: 100%;
        width: 100%;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  }

  .data-name {
    margin: 25px 0;

    h1 {
      font-size: 1.2rem;
      margin: 0 0 10px 0;
    }

    p {
      color: var(--grey);
      font-size: 0.9rem;
    }
  }

  .navigator {
    display: flex;
    align-items: center;
    gap: 10px;

    .divider {
      color: var(--grey);
      font-size: 0.9rem;
    }

    .selected-link,
    .previous-link {
      font-size: 0.9rem;
    }

    .selected-link {
      color: var(--green);
    }

    .previous-link {
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--grey);
    }

    .icon {
      max-width: 24px;
      width: 100%;
    }

    a {
      text-decoration: none;
    }
  }
`;

export default Tents;
