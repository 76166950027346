import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment";

//icons
import { Exit } from "@styled-icons/boxicons-regular/Exit";

//redux
import { setUser } from "../redux/variables";
import { useDispatch } from "react-redux";

//api
import { getNotificationsApi } from "../../api/api-list";

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("token_expires_at");
    dispatch(setUser(null));
    navigate("/admin/login");
  };

  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    const apiUrl = `${getNotificationsApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        // console.log(response.data);

        const data = response.data;

        const addThreeHours = (reservations) => {
          if (reservations.length === 0 || reservations[0].length === 0) {
            return []; // Return empty array if reservations or reservations[0] is empty
          }

          return reservations.map((reservation) => ({
            ...reservation,
            created_at: moment(reservation.created_at)
              .add(3, "hours")
              .format("YYYY-MM-DD HH:mm:ss"),
          }));
        };

        if (data && data.length > 0) {
          const updatedNotifications = addThreeHours(data);
          setNotifications(updatedNotifications);
        } else {
          setNotifications([]);
        }

        // console.log(updatedNotifications);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Wrapper>
      <Container>
        <div className="nav-image">
          <img src="/images/logo-trees.png" alt="logo" />
        </div>

        <nav>
          <NavLink to="/admin" end>
            Panelis
          </NavLink>
          <NavLink to="/admin/calendar">Kalendārs</NavLink>
          <NavLink to="/admin/notifications">
            Paziņojumi
            {notifications?.length > 0 && (
              <>
                <div className="notifications-alert" />
              </>
            )}
          </NavLink>
          <NavLink to="/admin/settings">Iestatījumi</NavLink>
          <div className="logout-btn" onClick={() => logOut()}>
            <Exit className="logout-icon" />
          </div>
        </nav>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  max-width: 1250px;
  padding: 10px 20px 0;
  margin: 0 auto;
  background: var(--dashback);
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: var(--green);
      font-size: 0.95rem;
      padding: 8px 15px;
      border-radius: 5px;
      font-weight: 500;
      position: relative;

      .notifications-alert {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--green);
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    @media only screen and (max-width: 650px) {
      a {
        display: none;
      }
    }

    .active {
      background-color: var(--lightgreen);
      font-weight: 600;
    }

    .logout-btn {
      color: var(--green);
      font-size: 0.95rem;
      padding: 8px 15px;
      border-radius: 5px;
      font-weight: 500;
      background-color: var(--lightgrey2);
      cursor: pointer;
      margin: 0 0 0 5px;

      .logout-icon {
        max-width: 18px;
        width: 100%;
        color: var(--green);
      }
    }

    .logout-btn:hover {
      background-color: var(--lightgrey);
    }
  }

  .nav-image {
    img {
      max-width: 60px;
      width: 100%;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

export default NavBar;
