import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

//components
import NavBar from "../components/NavBar";
import NavBarMobile from "../components/NavBarMobile";
import Footer from "../components/Footer";
import { toast } from "react-toastify";

//icons
import { UserPlus } from "@styled-icons/boxicons-solid/UserPlus";
import { CheckmarkCircleOutline } from "@styled-icons/evaicons-outline/CheckmarkCircleOutline";

//api
import { getNotificationsApi, deleteNotificationApi } from "../../api/api-list";

//hooks
import useAuth from "../hooks/useAuth";
import { motion } from "framer-motion";

const NotificationsPage = () => {
  //auth
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  const variants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };
  //auth -- end

  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    const apiUrl = `${getNotificationsApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        // console.log(response.data);

        const data = response.data;

        const addThreeHours = (reservations) => {
          if (reservations.length === 0 || reservations[0].length === 0) {
            return []; // Return empty array if reservations or reservations[0] is empty
          }

          return reservations.map((reservation) => ({
            ...reservation,
            created_at: moment(reservation.created_at)
              .add(3, "hours")
              .format("YYYY-MM-DD HH:mm:ss"),
          }));
        };

        if (data && data.length > 0) {
          const updatedNotifications = addThreeHours(data);
          setNotifications(updatedNotifications);
          setLoading(false);
        } else {
          setNotifications([]);
          setLoading(false);
        }

        // console.log(updatedNotifications);
      });
  };

  const [date, setDate] = useState(null);
  const [dateShort, setDateShort] = useState(null);

  const getCurrentDate = () => {
    const daysOfWeek = [
      "Svētdiena",
      "Pirmdiena",
      "Otrdiena",
      "Trešdiena",
      "Ceturtdiena",
      "Piektdiena",
      "Sestdiena",
    ];

    const today = new Date();
    const dayOfWeek = daysOfWeek[today.getDay()];
    const dayOfMonth = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();

    setDate(`${dayOfWeek}, ${dayOfMonth}.${month}.${year}`);
    setDateShort(`${dayOfMonth}.${month}.${year}`);
  };

  const notifyNotification = () =>
    toast("Paziņojums atzīmēts kā izlasīts - dzēsts! 🤘");

  //reservation delete
  const deleteNotification = (id) => {
    // Include the booking ID in the URL
    const url = `${deleteNotificationApi}/${id}`;

    axios
      .delete(url, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        notifyNotification();
        getNotifications();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (auth !== null) {
      if (!auth) {
        navigate("/admin/login");
      } else {
        getCurrentDate();
        getNotifications();
      }
    }

    //eslint-disable-next-line
  }, [auth]);

  return (
    <Wrapper>
      {!loading && (
        <>
          <NavBarMobile />
          <motion.div initial="hidden" animate="visible" variants={variants}>
            <Sticky>
              <NavBar />
              <RouterAndTime>
                <div className="router-time-container">
                  <div className="heading-router">
                    <p>Vadība</p>
                    <Link to="/admin/notifications">/ Paziņojumi</Link>
                  </div>

                  <div className="heading-date">
                    <p>{date}</p>
                  </div>
                </div>
              </RouterAndTime>
            </Sticky>
            <Container>
              <div className="panel">
                <h3>Paziņojumi</h3>

                <NotificatinosList>
                  {notifications?.length > 0 ? (
                    <>
                      {notifications.map((notif) => (
                        <NotificationSingle>
                          <div className="notification-left">
                            <div className="notification-icon">
                              <UserPlus className="icon" />
                            </div>
                          </div>

                          <div className="notification-left">
                            <p>
                              <span>{notif?.name}</span>
                              {notif?.message}
                            </p>

                            <p className="notification-time">
                              Izveidots: {notif?.created_at}
                            </p>

                            <div
                              className="notification-read-btn"
                              onClick={() => deleteNotification(notif?.id)}
                            >
                              Atzīmēt kā izlasītu
                            </div>
                          </div>
                        </NotificationSingle>
                      ))}
                    </>
                  ) : (
                    <NoNotificationsWrapper>
                      <CheckmarkCircleOutline className="icon" />
                      <p>Izskatās, ka pašlaik nav jaunu paziņojumu.</p>
                    </NoNotificationsWrapper>
                  )}
                </NotificatinosList>
              </div>
              <Footer />
            </Container>
          </motion.div>
        </>
      )}
    </Wrapper>
  );
};

const NoNotificationsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  .icon {
    max-width: 24px;
    width: 100%;
    color: var(--green);
  }

  p {
    font-size: 0.9rem;
    color: var(--grey);
  }
`;

const NotificationSingle = styled.div`
  background-color: var(--dashbox);
  border-radius: 5px;
  padding: 20px;
  flex-basis: 30%;
  max-width: 625px;
  flex-grow: 1;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  border: 1px solid var(--lightgrey);
  position: relative;
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 765px) {
    flex-basis: 45%;
  }

  @media only screen and (max-width: 600px) {
    flex-basis: 100%;
  }

  .notification-time {
    margin: 10px 0;
    color: var(--grey);
    font-size: 0.75rem;
  }

  .notification-read-btn {
    color: var(--green);
    font-size: 0.85rem;
    padding: 8px 15px;
    border-radius: 5px;
    font-weight: 600;
    position: relative;
    background-color: var(--lightgreen);
    display: inline-block;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .notification-read-btn:hover {
    border-color: var(--green);
  }

  p {
    font-size: 0.85rem;
    line-height: 1.4;

    span {
      margin: 0 4px 0 0;
      font-size: 0.85rem;
      font-weight: 600;
    }
  }

  .notification-icon {
    height: 32px;
    width: 100%;
    max-width: 32px;
    min-width: 32px;
    border-radius: 5px;
    background-color: var(--lightgreen);
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      max-width: 22px;
      min-width: 22px;
      width: 100%;
      color: var(--green);
    }
  }
`;

const NotificatinosList = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

const RouterAndTime = styled.div`
  border-bottom: 1px solid var(--lightgrey);
  background-color: var(--dashback);

  .router-time-container {
    padding: 10px 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1250px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 760px) {
    top: 86px;
  }

  .heading-date {
    p {
      font-size: 0.9rem;
      color: var(--green);
      font-weight: 600;
      text-align: end;
    }
  }

  .heading-router {
    display: flex;
    gap: 5px;
    align-items: center;

    p {
      font-size: 0.9rem;
      color: var(--grey);
    }

    a {
      font-size: 0.9rem;
      color: var(--green);
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const Container = styled.div`
  flex: 1;
  padding-bottom: 200px;

  .panel {
    padding: 20px;
    max-width: 1250px;
    margin: 0 auto;

    h3 {
      font-size: 1.2rem;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: var(--dashback);
`;

export default NotificationsPage;
