import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

//icons
import { Sunny } from "@styled-icons/ionicons-outline/Sunny";
import { Water } from "@styled-icons/boxicons-regular/Water";
import { KeyboardArrowDown } from "@styled-icons/material-sharp/KeyboardArrowDown";
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline";
import { PhoneCall } from "@styled-icons/boxicons-regular/PhoneCall";
import { Language } from "@styled-icons/material-outlined/Language";

import { NavLink, Link, useLocation } from "react-router-dom";

//components
import { Turn as Hamburger } from "hamburger-react";
import PuffLoader from "react-spinners/PuffLoader";

//api
import {
  getHomepageStatusApi,
  getTemperaturesUpdated,
} from "../../api/api-list";

//translation
import { useTranslation } from "react-i18next";

const Header = () => {
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);
  const [temperatureData, setTemperatureData] = useState(null);
  const [homepageStatus, setHomepageStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const { t, i18n } = useTranslation();

  const fetchTemperatureData = async () => {
    try {
      const response = await axios.get(getTemperaturesUpdated);

      let data = response.data;
      const tempValue = data.temperature.match(/\d+/)[0];

      data.temperature = `${tempValue}°C`;

      setTemperatureData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getHomepageStatus = () => {
    const apiUrl = `${getHomepageStatusApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        const status = response?.data?.homepage_status > 0 ? true : false;
        setHomepageStatus(status);
      });
  };

  const handleShowLanguageSelect = () => {
    setShowLanguageSelect((showLanguageSelect) => !showLanguageSelect);
  };

  const [isOpen, setOpen] = useState(false);
  const [isPathCampsite, setIsPathCampsite] = useState(false);
  const [isPathAbout, setIsPathAbout] = useState(false);

  const location = useLocation();

  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const updateLang = (lang) => {
    localStorage.setItem("lang", lang);
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (
      location.pathname === "/houses" ||
      location.pathname === "/tents" ||
      location.pathname === "/trailers"
    ) {
      setIsPathCampsite(true);
    } else {
      setIsPathCampsite(false);
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/gallery" || location.pathname === "/about-us") {
      setIsPathAbout(true);
    } else {
      setIsPathAbout(false);
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    fetchTemperatureData();
    getHomepageStatus();
  }, []);

  useEffect(() => {
    if (temperatureData) {
      setLoading(false);
    }
  }, [temperatureData]);

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
      setSelectedLanguage("latvian");
    } else {
      setSelectedLanguage(lang);
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <div className="navbar-small-bar">
        <div className="navbar-container">
          <div className="navbar-small-bar-items">
            {loading ? (
              <PuffLoader
                color={"var(--green)"}
                loading={loading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <>
                <div className="navbar-small-bar-item">
                  <div className="navbar-small-bar-item-icon">
                    <div
                      className="open-status"
                      style={{
                        backgroundColor: homepageStatus
                          ? "var(--green)"
                          : "var(--red)",
                      }}
                    />
                  </div>
                  {homepageStatus ? (
                    <p style={{ color: "var(--green)" }}> {t("open")}</p>
                  ) : (
                    <p style={{ color: "var(--red)" }}> {t("closed")}</p>
                  )}
                </div>

                {temperatureData?.temperature && (
                  <>
                    <div className="navbar-small-bar-item">
                      <div className="navbar-small-bar-item-icon">
                        <Sunny className="icon" />
                      </div>
                      <p>{temperatureData?.temperature}</p>
                    </div>
                  </>
                )}

                {temperatureData?.water_temperature && (
                  <>
                    <div className="navbar-small-bar-item">
                      <div className="navbar-small-bar-item-icon">
                        <Water className="icon" />
                      </div>
                      <p>{temperatureData?.water_temperature}</p>
                    </div>
                  </>
                )}
              </>
            )}

            <div
              className="navbar-small-bar-item language-select"
              onClick={handleShowLanguageSelect}
            >
              <div className="navbar-small-bar-item-icon">
                <Language className="icon" />
                {selectedLanguage === "latvian" && <p>Latviski</p>}
                {selectedLanguage === "english" && <p>English</p>}
                {selectedLanguage === "russian" && <p>Русский</p>}
                {showLanguageSelect && (
                  <>
                    <div className="language-select-languages">
                      <p onClick={() => updateLang("latvian")}>Latviski</p>
                      <p onClick={() => updateLang("english")}>English</p>
                      <p onClick={() => updateLang("russian")}>Русский</p>
                    </div>
                  </>
                )}
              </div>

              <KeyboardArrowDown
                className="icon"
                style={{
                  transform: showLanguageSelect
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="navbar-big-bar">
        <div className="navbar-container">
          <div className="navbar-big-bar-nav">
            <nav>
              <NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
                {t("home")}
              </NavLink>
              <div
                className="navbar-big-bar-nav-select"
                style={{
                  borderBottom: isPathCampsite
                    ? "2px solid var(--green)"
                    : "2px solid transparent",
                  color: isMouseDown
                    ? "var(--white)"
                    : isPathCampsite
                    ? "var(--green)"
                    : "var(--black)",
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                {t("campsite")}
                <KeyboardArrowDown className="icon" />
                <div className="navbar-big-bar-nav-select-dropdown">
                  <Link to="/houses" onClick={() => window.scrollTo(0, 0)}>
                    {t("houses")}
                  </Link>
                  <Link to="/tents" onClick={() => window.scrollTo(0, 0)}>
                    {t("tents")}
                  </Link>
                  <Link to="/trailers" onClick={() => window.scrollTo(0, 0)}>
                    {t("trailers")}
                  </Link>
                </div>
              </div>
              <NavLink to="/pricing" onClick={() => window.scrollTo(0, 0)}>
                {t("pricing")}
              </NavLink>
              <div
                className="navbar-big-bar-nav-select"
                style={{
                  borderBottom: isPathAbout
                    ? "2px solid var(--green)"
                    : "2px solid transparent",
                  color: isMouseDown
                    ? "var(--white)"
                    : isPathAbout
                    ? "var(--green)"
                    : "var(--black)",
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                {t("about")}
                <KeyboardArrowDown className="icon" />
                <div className="navbar-big-bar-nav-select-dropdown">
                  <Link to="/gallery" onClick={() => window.scrollTo(0, 0)}>
                    {t("gallery")}
                  </Link>
                  <Link to="/map" onClick={() => window.scrollTo(0, 0)}>
                    {t("map")}
                  </Link>
                </div>
              </div>
            </nav>
          </div>

          <div className="navbar-big-bar-logo">
            <img src="../images/logo.png" alt="logo" />
          </div>

          <div className="navbar-big-bar-action">
            <div className="navbar-big-bar-action-contact">
              <div className="navbar-big-bar-action-contact-item">
                <Link to="mailto:info@plaucaki.lv">
                  <EmailOutline className="icon" />
                  <p>info@plaucaki.lv</p>
                </Link>
              </div>

              <div className="navbar-big-bar-action-contact-item">
                <Link to="tel:+37126445124">
                  <PhoneCall className="icon" />
                  <p>+371 26 445 124</p>
                </Link>
              </div>
            </div>

            <div>
              <Link
                to="/reserve"
                className="navbar-big-bar-action-reserve"
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("reserve")}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar-mobile-bar">
        <Link to="/">
          <img src="../images/logo-camp.png" alt="logo" />
        </Link>
        <div className="navbar-mobile-bar-icon">
          <Hamburger toggled={isOpen} toggle={setOpen} size={24} />
        </div>
      </div>

      {isOpen && (
        <>
          <div className="navbar-mobile-menu">
            <div className="navbar-mobile-menu-list">
              <div className="navbar-small-bar-items">
                <div className="navbar-small-bar-items-top">
                  <div className="navbar-small-bar-item">
                    <div className="navbar-small-bar-item-icon">
                      <div
                        className="open-status"
                        style={{
                          backgroundColor: homepageStatus
                            ? "var(--green)"
                            : "var(--red)",
                        }}
                      />
                    </div>
                    {homepageStatus ? (
                      <p style={{ color: "var(--green)" }}> {t("open")}</p>
                    ) : (
                      <p style={{ color: "var(--red)" }}> {t("closed")}</p>
                    )}
                  </div>

                  {temperatureData?.temperature && (
                    <>
                      <div className="navbar-small-bar-item">
                        <div className="navbar-small-bar-item-icon">
                          <Sunny className="icon" />
                        </div>
                        <p>{temperatureData?.temperature}</p>
                      </div>
                    </>
                  )}

                  {temperatureData?.water_temperature && (
                    <>
                      <div className="navbar-small-bar-item">
                        <div className="navbar-small-bar-item-icon">
                          <Water className="icon" />
                        </div>
                        <p>{temperatureData?.water_temperature}</p>
                      </div>
                    </>
                  )}
                </div>

                <div className="navbar-small-bar-items-bottom">
                  <div
                    className="navbar-small-bar-item language-select"
                    onClick={() => updateLang("latvian")}
                  >
                    <div className="navbar-small-bar-item-icon">
                      <Language className="icon" />
                      <p>Latviski</p>
                    </div>
                  </div>

                  <div
                    className="navbar-small-bar-item language-select"
                    onClick={() => updateLang("english")}
                  >
                    <div className="navbar-small-bar-item-icon">
                      <Language className="icon" />
                      <p>In English</p>
                    </div>
                  </div>

                  <div
                    className="navbar-small-bar-item language-select"
                    onClick={() => updateLang("russian")}
                  >
                    <div className="navbar-small-bar-item-icon">
                      <Language className="icon" />
                      <p>Русский</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <NavLink
                  to="/"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  {t("home")}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/houses"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  {t("houses")}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/tents"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  {t("tents")}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/trailers"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  {t("trailers")}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/pricing"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  {t("pricing")}
                </NavLink>
              </div>

              <div>
                <NavLink
                  to="/gallery"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  {t("gallery")}
                </NavLink>
              </div>

              <div>
                <NavLink
                  to="/map"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  {t("map")}
                </NavLink>
              </div>

              <div>
                <Link
                  to="/reserve"
                  className="btn"
                  onClick={(e) => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  {t("reserve")}
                </Link>
              </div>

              <div className="navbar-big-bar-action-contact">
                <div className="navbar-big-bar-action-contact-item">
                  <Link to="mailto:info@plaucaki.lv">
                    <EmailOutline className="icon" />
                    <p>info@plaucaki.lv</p>
                  </Link>
                </div>

                <div className="navbar-big-bar-action-contact-item">
                  <Link to="tel:+37126445124">
                    <PhoneCall className="icon" />
                    <p>+371 26 445 124</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: #fdfdfded;

  .navbar-mobile-menu {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 20px 20px 150px 20px;
    background-color: var(--white);

    @media only screen and (max-height: 725px) {
      overflow-y: scroll !important;
    }

    .navbar-mobile-menu-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 35px;
      padding: 20px 0 0 0;

      .navbar-big-bar-action-contact {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 0 0 150px 0;

        .navbar-big-bar-action-contact-item {
          a {
            display: flex;
            gap: 10px;
            align-items: center;
            color: var(--green);
            text-decoration: none;

            p {
              font-size: 0.7rem;
            }

            .icon {
              max-width: 18px;
              width: 100%;
            }
          }

          a:hover p {
            text-decoration: underline;
          }
        }
      }

      a {
        text-decoration: none;
        color: var(--green);
        font-size: 1.2rem;
        padding: 0 0 5px 0;
        font-weight: 400;
      }

      a.active {
        border-bottom: 2px solid var(--green);
        font-weight: 600;
        color: var(--green);
      }

      .btn {
        cursor: pointer;
        color: var(--white);
        background-color: var(--green);
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 500;
        padding: 8px 15px;
        border-radius: 5px;
      }

      .navbar-small-bar-items {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-direction: column;

        .navbar-small-bar-items-top,
        .navbar-small-bar-items-bottom {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .navbar-small-bar-item {
          display: flex;
          align-items: center;
          gap: 5px;

          .icon {
            min-width: 18px;
            color: var(--green);
          }

          .navbar-small-bar-item-icon {
            display: flex;
            align-items: center;
            gap: 5px;

            p {
              font-size: 0.8rem;
              font-weight: 600;
              color: var(--green);
              min-width: 70px;
            }

            .icon {
              min-width: 20px;
              color: var(--green);
            }
          }

          .open-status {
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }

          p {
            font-size: 0.8rem;
            font-weight: 600;
            color: var(--green);
          }
        }
      }
    }
  }

  .navbar-mobile-bar {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    position: relative;
    backdrop-filter: blur(6px);
    padding: 10px 10px 8px;
    justify-content: space-between;
    align-items: center;

    display: none;

    @media only screen and (max-width: 1080px) {
      display: flex;
    }

    img {
      max-width: 100px;
      width: 100%;
    }
  }

  .navbar-big-bar {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    position: relative;
    backdrop-filter: blur(6px);

    @media only screen and (max-width: 1080px) {
      display: none;
    }

    .navbar-big-bar-action {
      display: flex;
      gap: 25px;
      align-items: center;

      .navbar-big-bar-action-contact {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .navbar-big-bar-action-contact-item {
          a {
            display: flex;
            gap: 10px;
            align-items: center;
            color: var(--green);
            text-decoration: none;

            p {
              font-size: 0.7rem;
            }

            .icon {
              max-width: 18px;
              width: 100%;
            }
          }

          a:hover p {
            text-decoration: underline;
          }
        }
      }

      .navbar-big-bar-action-reserve {
        cursor: pointer;
        color: var(--white);
        background-color: var(--green);
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 500;
        padding: 8px 15px;
        border-radius: 5px;
      }

      .navbar-big-bar-action-reserve:hover {
        opacity: 0.9;
      }
    }

    .navbar-big-bar-logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      img {
        max-width: 100px;
        position: relative;
        top: 4px;
      }
    }

    nav {
      display: flex;

      .navbar-big-bar-nav-select:hover .navbar-big-bar-nav-select-dropdown {
        display: flex;
      }

      .navbar-big-bar-nav-select:hover .icon {
        transform: rotate(180deg);
      }

      .navbar-big-bar-nav-select-dropdown {
        position: absolute;
        z-index: 100;
        bottom: -55px;
        left: 0;
        background: var(--white);
        padding: 5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        display: none;

        a {
          padding: 15px;
          min-width: 130px;
          display: inline-block;
          text-align: center;
          font-size: 0.9rem;
        }
      }

      a,
      .navbar-big-bar-nav-select {
        color: var(--black);
        text-decoration: none;
        padding: 24px 18px;
        font-weight: 500;
        transition: 0.3s linear;
        display: flex;
        align-items: center;
        gap: 5px;
        position: relative;
        cursor: pointer;
        border-bottom: 2px solid transparent;

        .icon {
          max-width: 20px;
          width: 100%;
        }
      }

      a.active {
        color: var(--green);
        border-bottom: 2px solid var(--green);
        font-weight: 600;
      }

      a:hover,
      .navbar-big-bar-nav-select:hover {
        color: var(--white) !important;
        background-color: var(--green);
      }
    }

    .navbar-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .navbar-small-bar {
    background-color: var(--lightgrey2);
    padding: 4px 0;

    @media only screen and (max-width: 1080px) {
      display: none;
    }

    .navbar-small-bar-items {
      display: flex;
      justify-content: end;
      gap: 20px;

      .language-select {
        cursor: pointer;
        position: relative;

        .language-select-languages {
          position: absolute;
          z-index: 100;
          bottom: -90px;
          right: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
          background: var(--white);
          padding: 10px 15px;
          border-radius: 5px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          p {
            font-size: 1rem;
            border-bottom: 1px solid var(--white);
          }

          p:hover {
            border-bottom: 1px solid var(--green);
          }
        }
      }

      .navbar-small-bar-item {
        display: flex;
        align-items: center;
        gap: 5px;

        .icon {
          min-width: 18px;
          color: var(--green);
        }

        .navbar-small-bar-item-icon {
          display: flex;
          align-items: center;
          gap: 5px;

          p {
            font-size: 0.8rem;
            font-weight: 600;
            color: var(--green);
          }

          .icon {
            min-width: 20px;
            color: var(--green);
          }
        }

        .open-status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        p {
          font-size: 0.8rem;
          font-weight: 600;
          color: var(--green);
        }
      }
    }
  }

  .navbar-container {
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    @media only screen and (max-width: 1260px) {
      padding: 0 10px;
    }
  }
`;

export default Header;
