import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

//translation
import { useTranslation } from "react-i18next";

const Map = () => {
  const { t, i18n } = useTranslation();

  const [imageUrl, setImageUrl] = useState("");
  const [mapLanguage, setMapLanguage] = useState("latvian");

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mapLanguage === "latvian") {
      setImageUrl("./images/map/map-latvian.png");
    } else if (mapLanguage === "english") {
      setImageUrl("./images/map/map-english.png");
    } else {
      setImageUrl("./images/map/map-rus.png");
    }

    //eslint-disable-next-line
  }, [mapLanguage]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("map_title")}</title>
      </Helmet>

      <div className="heading">
        <h3>{t("map_h3")}</h3>
      </div>

      <div className="map">
        <div className="map-top">
          <div className="map-languages">
            <div
              className="map-language"
              style={{
                backgroundColor: mapLanguage === "latvian" && "var(--green)",
              }}
              onClick={() => setMapLanguage("latvian")}
            >
              <p style={{ color: mapLanguage === "latvian" && "var(--white)" }}>
                Latviski
              </p>
            </div>

            <div
              className="map-language"
              style={{
                backgroundColor: mapLanguage === "english" && "var(--green)",
              }}
              onClick={() => setMapLanguage("english")}
            >
              <p style={{ color: mapLanguage === "english" && "var(--white)" }}>
                English
              </p>
            </div>

            <div
              className="map-language"
              style={{
                backgroundColor: mapLanguage === "russian" && "var(--green)",
              }}
              onClick={() => setMapLanguage("russian")}
            >
              <p style={{ color: mapLanguage === "russian" && "var(--white)" }}>
                Русский
              </p>
            </div>
          </div>
        </div>

        <div className="map-bottom">
          <img src={imageUrl} alt="map" />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto 40px;

  .map {
    .map-top {
      .map-languages {
        padding: 0 20px;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin: 10px 0 30px;

        .map-language {
          padding: 8px 15px;
          border-radius: 5px;
          border: 1px solid var(--green);
          cursor: pointer;
          transition: 0.2s linear;

          p {
            font-size: 0.9rem;
            color: var(--green);
            transition: 0.2s linear;
          }
        }
      }
    }

    .map-bottom {
      width: 100%;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .heading {
    padding: 20px 20px 10px 20px;
    margin: 10px 0 0 0;

    h3 {
      font-size: 1.2rem;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
`;

export default Map;
