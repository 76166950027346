import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

//translation
import { useTranslation } from "react-i18next";

const LandingSlideShow = () => {
  const { t, i18n } = useTranslation();

  const images = [
    "/images/landing-slideshow/0.png",
    "/images/landing-slideshow/1.png",
    "/images/landing-slideshow/2.png",
    "/images/landing-slideshow/3.png",
  ];

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Fade indicators={true} arrows={false} duration={7000}>
        <div className="each-slide">
          <div>
            <div className="slideshow-text">
              <p>{t("slideshow_p1")}</p>
              <h1>{t("slideshow_h1")}</h1>
              <div className="slideshow-links">
                <Link to="/reserve" className="button">
                  {t("reserve")}
                </Link>
                {/* <Link to="/reserve" className="learn-more">
                  Uzzināt vairāk
                </Link> */}
              </div>
            </div>
            <img src={images[0]} alt="slideshow-pic" />
          </div>
        </div>

        <div className="each-slide">
          <div>
            <div className="slideshow-text">
              <p>{t("slideshow_p2")}</p>
              <h1>{t("slideshow_h2")}</h1>
              {/* <div className="slideshow-links">
                <Link to="/reserve" className="button">
                  Rezervēt
                </Link>
                <Link to="/reserve" className="learn-more">
                  Uzzināt vairāk
                </Link>
              </div> */}
            </div>
            <img src={images[1]} alt="slideshow-pic" />
          </div>
        </div>

        <div className="each-slide">
          <div>
            <div className="slideshow-text">
              <p>{t("slideshow_p3")}</p>
              <h1>{t("slideshow_h3")}</h1>
              <div className="slideshow-links">
                <Link to="/reserve" className="button">
                  {t("reserve")}
                </Link>
                {/* <Link to="/reserve" className="learn-more">
                  Uzzināt vairāk
                </Link> */}
              </div>
            </div>
            <img src={images[2]} alt="slideshow-pic" />
          </div>
        </div>

        <div className="each-slide">
          <div>
            <div className="slideshow-text">
              <p>{t("slideshow_p4")}</p>
              <h1>{t("slideshow_h4")}</h1>
              {/* <div className="slideshow-links">
                <Link to="/reserve" className="button">
                  Rezervēt
                </Link>
                <Link to="/reserve" className="learn-more">
                  Uzzināt vairāk
                </Link>
              </div> */}
            </div>
            <img src={images[3]} alt="slideshow-pic" />
          </div>
        </div>
      </Fade>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0px;
  position: relative;

  @media only screen and (max-width: 1350px) {
    padding: 0;
    max-width: unset;
  }

  .indicators {
    position: absolute;
    bottom: 40px;
    z-index: 80;
    right: 5%;
    background-color: var(--white);
    padding: 10px 6px 7px 10px;
    border-radius: 5px;
  }

  .indicator.active {
    color: var(--white);
  }

  .slideshow-text {
    z-index: 50;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 5%;
    color: white;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      font-size: 0.9rem;
      opacity: 0.8;
      font-weight: 300;
      text-shadow: 1px 5px 15px #000;
      color: var(--white);
    }

    h1 {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.4;
      text-shadow: 1px 5px 15px #000;
      color: var(--white);
    }

    .slideshow-links {
      display: flex;
      gap: 15px;
      align-items: center;

      a {
        color: var(--white);
        text-decoration: none;
      }

      .button {
        text-transform: uppercase;
        padding: 8px 15px;
        border-radius: 5px;
        border: 2px solid var(--white);
        font-size: 0.9rem;
        transition: 0.2s linear;
      }

      .button:hover {
        background-color: var(--green);
        border: 2px solid var(--green);
      }

      .learn-more {
        font-size: 0.8rem;
        border-bottom: 1px solid var(--white);
      }

      .learn-more:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .react-slideshow-fadezoom-wrapper {
    max-height: 460px;
    min-height: 460px;
    height: 100%;
    border-radius: 5px;

    @media only screen and (max-width: 1350px) {
      border-radius: 0;
    }

    img {
      filter: brightness(65%) contrast(110%);
      -webkit-filter: brightness(65%) contrast(110%);
      -moz-filter: brightness(65%) contrast(110%);
      width: 100%;
      height: 100%;
      min-height: 460px;
      object-fit: cover;
      background-color: var(--lightgrey2);
    }
  }
`;

export default LandingSlideShow;
