import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

//components
import NavBar from "../components/NavBar";
import NavBarMobile from "../components/NavBarMobile";
import moment from "moment";
import "moment/locale/lv";
import Switch from "react-switch";
import Footer from "../components/Footer";

//api
import {
  getHomepageStatusApi,
  updateHomepageStatusApi,
} from "../../api/api-list";

import { motion } from "framer-motion";

//hooks
import useAuth from "../hooks/useAuth";

moment.locale("lv");

const Settings = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const variants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  //current date
  const [date, setDate] = useState(null);

  const getCurrentDate = () => {
    const daysOfWeek = [
      "Svētdiena",
      "Pirmdiena",
      "Otrdiena",
      "Trešdiena",
      "Ceturtdiena",
      "Piektdiena",
      "Sestdiena",
    ];

    const today = new Date();
    const dayOfWeek = daysOfWeek[today.getDay()];
    const dayOfMonth = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();

    setDate(`${dayOfWeek}, ${dayOfMonth}.${month}.${year}.`);
  };

  //current date -- end

  //campsite status
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenStatus = (checked) => {
    setIsOpen(checked);
    updateHomepageStatus(checked);
  };

  const getHomepageStatus = () => {
    const apiUrl = `${getHomepageStatusApi}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        const status = response?.data?.homepage_status > 0 ? true : false;
        setIsOpen(status);
      });
  };

  const updateHomepageStatus = (status) => {
    const formData = {
      status: status ? 1 : 0,
    };

    // Send reservation data to backend API
    axios
      .post(updateHomepageStatusApi, formData, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("updated status!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (auth !== null) {
      if (!auth) {
        navigate("/admin/login");
      } else {
        getCurrentDate();
        getHomepageStatus();
      }
    }

    //eslint-disable-next-line
  }, [auth]);

  return (
    <Wrapper>
      <NavBarMobile />
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <Sticky>
          <NavBar />
          <RouterAndTime>
            <div className="router-time-container">
              <div className="heading-router">
                <p>Vadība</p>
                <Link to="/admin/calendar">/ Iestatījumi</Link>
              </div>

              <div className="heading-date">
                <p>{date}</p>
              </div>
            </div>
          </RouterAndTime>
        </Sticky>
        <Dash>
          <DashLeft>
            <div className="content">
              <h3>Mājaslapas iestatījumi</h3>
              <div className="content-setting-list">
                <Setting>
                  <div className="setting-left-side">
                    <h3>Kempinga statuss (atvērts/slēgts)</h3>
                    <p>
                      Indikators mājaslapas augšā, kempings ir atvērts vai
                      slēgts. Kā arī, atļauj vai liedz iespēju lietotājam veikt
                      rezervācijas.
                    </p>
                  </div>

                  <div className="setting-right-side">
                    <Switch onChange={handleOpenStatus} checked={isOpen} />
                  </div>
                </Setting>
              </div>
            </div>
            <Footer />
          </DashLeft>
        </Dash>
      </motion.div>
    </Wrapper>
  );
};

const Setting = styled.div`
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  border: 1px solid var(--lightgrey);
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .setting-left-side {
    max-width: 300px;

    h3 {
      font-size: 1.05rem !important;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;

const DashLeft = styled.div`
  flex: 1;



  .content {
    padding: 20px;

    h3 {
      font-size: 1.2rem;
    }

    .content-setting-list {
      display: flex;
      justify-content: flex-start;
      margin: 20px 0 40px 0;
    }
  }
`;

const Dash = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 300px 0;
  max-width: 1250px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: var(--dashback);
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const RouterAndTime = styled.div`
  border-bottom: 1px solid var(--lightgrey);
  background-color: var(--dashback);

  .router-time-container {
    padding: 10px 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1250px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 760px) {
    top: 86px;
  }

  .heading-date {
    p {
      font-size: 0.9rem;
      color: var(--green);
      font-weight: 600;
      text-align: end;
    }
  }

  .heading-router {
    display: flex;
    gap: 5px;
    align-items: center;

    p {
      font-size: 0.9rem;
      color: var(--grey);
    }

    a {
      font-size: 0.9rem;
      color: var(--green);
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export default Settings;
