// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './english.json';
import lvTranslation from './latvian.json';
import ruTranslation from './russian.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      english: {
        translation: enTranslation,
      },
      latvian: {
        translation: lvTranslation,
      },
      russian: {
        translation: ruTranslation,
      },
    },
    lng: 'latvian', // Default language
    fallbackLng: 'latvian', // Fallback language if translation not found
    interpolation: {
      escapeValue: false, // React already escapes string
    },
    react: {
      useSuspense: false, // Set to true if you want to use Suspense for loading translations
    },
  });

export default i18n;
