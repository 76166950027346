import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

//components
import PageHeading from "../components/PageHeading";

//translation
import { useTranslation } from "react-i18next";

const Pricing = () => {
  const { t, i18n } = useTranslation();

  const pricing = [
    {
      name: t("pricing_p1"),
      price: t("pricing_p2"),
    },
    { name: t("pricing_p3"), price: t("pricing_p4") },
    { name: t("pricing_p5"), price: t("pricing_p6") },
    { name: t("pricing_p7"), price: t("pricing_p8") },
    { name: t("pricing_p9"), price: t("pricing_p10") },
    { name: t("pricing_p11"), price: t("pricing_p12") },
    { name: t("pricing_p13"), price: t("pricing_p14") },
    { name: t("pricing_p15"), price: t("pricing_p16") },
    { name: t("pricing_p17"), price: t("pricing_p18") },
  ];

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("pricing_title")}</title>
      </Helmet>
      <PageHeading
        image={"../images/page-headings/pricing.png"}
        imagePosition={"70% 50%"}
        text={t("pricing_h")}
      />

      <div className="heading">
        <h3>{t("pricing_h3")}</h3>
      </div>

      <div className="pricing-list">
        {pricing?.map((item) => (
          <div className="pricing-list-item" key={item?.name}>
            <p className="pricing-list-item-name">{item?.name}</p>
            <div className="pricing-list-item-divider" />
            <p className="pricing-list-item-price">{item?.price}</p>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;

  .pricing-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 20px 40px;

    .pricing-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
        border-bottom: 1px dotted var(--lightgrey);
        padding: 0 0 30px 0;
      }

      p {
        font-size: 1rem;
      }

      .pricing-list-item-price {
        color: var(--green);
        font-weight: 600;
      }

      .pricing-list-item-divider {
        flex: 1;
        border-bottom: 1px dotted var(--lightgrey);

        @media only screen and (max-width: 600px) {
          display: none;
        }
      }
    }
  }

  .heading {
    padding: 20px 20px 10px 20px;
    margin: 10px 0 0 0;

    h3 {
      font-size: 1.2rem;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
`;

export default Pricing;
