const apiLink = process.env.REACT_APP_API_LINK;
const getTemperatures = `${apiLink}/get-temperatures.php`;
const getTemperaturesUpdated = `${apiLink}/get-temperatures-updated.php`;
const getAccommodationBookings = `${apiLink}/get-accommodation-bookings.php?accommodation_number=`;

const createReservation = `${apiLink}/create-reservation.php`;
const deleteReservationApi = `${apiLink}/delete-reservation.php`;
const updateReservationApi = `${apiLink}/update-reservation.php`;

const getAllBookingsDashboard = `${apiLink}/get-all-bookings-dashboard.php`;
const getAllDataDashboard = `${apiLink}/get-all-data-dashboard.php`;
const getReservationRevenueApi = `${apiLink}/get-reservation-revenue.php`;
const getReservationTotalCountApi = `${apiLink}/get-reservation-total-count.php`;

const updateReservationStatusApi = `${apiLink}/update-reservation-status.php`;
const updateReservationPriceApi = `${apiLink}/update-reservation-price.php`;

const createNotificationApi = `${apiLink}/create-notification.php`;
const getNotificationsApi = `${apiLink}/get-notifications.php`;
const deleteNotificationApi = `${apiLink}/delete-notification.php`;

const getHomepageStatusApi = `${apiLink}/get-homepage-status.php`;
const updateHomepageStatusApi = `${apiLink}/update-homepage-status.php`;

const logInDashboardApi = `${apiLink}/log-in-dashboard.php`;
const getUser = `${apiLink}/get-user.php?user_token=`;

const getAvailableAccommodations = `${apiLink}/get-available-accommodations.php`;

const sendReservationConfirmationApi = `${apiLink}/send-reservation-confirmation.php`;

export {
  createReservation,
  createNotificationApi,
  getNotificationsApi,
  getTemperatures,
  getAccommodationBookings,
  getAllBookingsDashboard,
  getAllDataDashboard,
  getReservationRevenueApi,
  getReservationTotalCountApi,
  getHomepageStatusApi,
  updateReservationStatusApi,
  updateReservationPriceApi,
  updateHomepageStatusApi,
  deleteReservationApi,
  logInDashboardApi,
  getUser,
  getAvailableAccommodations,
  sendReservationConfirmationApi,
  getTemperaturesUpdated,
  deleteNotificationApi,
  updateReservationApi
};
