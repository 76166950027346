import React, { useState, useEffect } from "react";
import styled from "styled-components";

//data
import { appVersion } from "../../api/global-data";

const Footer = () => {
  return (
    <Wrapper>
      <p className='version'>PBMS {appVersion}</p>
      <p>Plaucaki Booking Management System</p>
      <p>Powered by ansisarthur</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: var(--dashback);
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;

  p {
    font-size: 0.8rem;
    text-align: center;
  }

  .version {
    color: var(--green);
    font-weight: 600;
    font-size: .9rem;
    
  }
`;

export default Footer;
