import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";

const CookiesAlert = () => {
  const [alert, showAlert] = useState("");
  const { t, i18n } = useTranslation();

  const allowCookies = () => {
    window.localStorage.setItem("cookies-allowed", true);
    showAlert(false);
  };

  useEffect(() => {
    const cookiesCheck = window.localStorage.getItem("cookies-allowed");

    if (!cookiesCheck) {
      showAlert(true);
    } else {
      showAlert(false);
    }
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      {alert && (
        <div className="cookies-alert">
          <h3>{t("cookies_h3")}</h3>

          <p>{t("cookies_p")}</p>

          <div className="cookies-alert-bottom">
            <Link to='/privacy-policy'>{t("cookies_policy")}</Link>

            <div onClick={() => allowCookies()} className="contact-btn">
              <p>{t("cookies_btn")}</p>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .cookies-alert {
    max-width: 1200px;
    min-width: 400px;
    padding: 20px;
    margin: 0 auto;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: var(--green);
    max-width: 300px;
    z-index: 150;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media only screen and (max-width: 650px) {
      min-width: unset;
      max-width: unset;
      width: 95%;
      left: 50%;
      transform: translateX(-50%);
    }

    h3 {
      font-size: 1.2rem;
      color: var(--white);
    }

    p {
      font-size: 0.9rem;
      color: var(--white);
    }

    .cookies-alert-bottom {
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 15px;

      a {
        color: var(--white);
        font-size: .9rem;
        position: relative;
        top: 3px;
        text-decoration: underline;
      }

      a:hover {
        text-decoration: none;
      }

      .contact-btn {
        cursor: pointer;
        transition: 0.4s ease-in-out;
        margin: 10px 0 0 0;

        p {
          color: var(--black);
          font-size: 0.8rem;
          background: var(--white);
          padding: 8px 15px;
          font-weight: 600;
          transition: 0.4s ease-in-out;
          border-radius: 5px;
        }
      }

      .contact-btn:hover {
        p {
          opacity: 0.7;
        }
      }
    }
  }
`;

export default CookiesAlert;
