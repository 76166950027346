import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

//components
import PageHeading from "../components/PageHeading";

//translation
import { useTranslation } from "react-i18next";

const Rules = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  const privacyPolicy = [
    {
      heading: t("privacy_head_1"),
      subHeading: t("privacy_sub_head_1"),
      rules: [
        t("privacy_1_p1"),
        t("privacy_1_p2"),
        t("privacy_1_p3"),
        t("privacy_1_p4"),
        t("privacy_1_p5"),
      ],
    },
    {
      heading: t("privacy_head_2"),
      subHeading: t("privacy_sub_head_2"),
      rules: [
        t("privacy_2_p1"),
        t("privacy_2_p2"),
        t("privacy_2_p3"),
        t("privacy_2_p4"),
      ],
    },
    {
      heading: t("privacy_head_3"),
      subHeading: t("privacy_sub_head_3"),
      rules: [
        t("privacy_3_p1"),
        t("privacy_3_p2"),
        t("privacy_3_p3"),
      ],
    },
    {
      heading: t("privacy_head_4"),
      subHeading: t("privacy_sub_head_4"),
    },
    {
      heading: t("privacy_head_5"),
      subHeading: t("privacy_sub_head_5"),
    },
    {
      heading: t("privacy_head_6"),
      subHeading: t("privacy_sub_head_6"),
      rules: [
        t("privacy_6_p1"),
        t("privacy_6_p2"),
        t("privacy_6_p3"),
      ],
    },
    {
      heading: t("privacy_head_7"),
      subHeading: t("privacy_sub_head_7"),
    },
    {
      heading: t("privacy_head_8"),
      subHeading: t("privacy_sub_head_8"),
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("privacy_title")}</title>
      </Helmet>
      <PageHeading
        image={"../images/page-headings/rules.png"}
        imagePosition={"70% 50%"}
        text={t("privacy_heading")}
      />
      <p className="description">{t("privacy_desc")}</p>
      <ul className="rules-list">
        {privacyPolicy?.map((rule, i) => (
          <li key={i}>
            <h3>{rule?.heading}</h3>
            <h4>{rule?.subHeading}</h4>

            {rule?.rules?.length > 0 && (
              <>
                <ul className="point-list">
                  {rule?.rules.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
      </ul>
      <p className="description" style={{ marginBottom: 40 }}>
        {t("privacy_desc_b")}
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;

  .point-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 40px 20px;
    list-style-type: decimal;

    li {
      font-size: 1rem;
    }
  }

  .description {
    font-size: 1rem;
    padding: 0 20px;
    line-height: 1.4;
    margin: 40px 0px 20px;
  }

  .rules-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 40px 40px;
    list-style-type: decimal;

    h3 {
      font-weight: 600;
      font-size: 1.2rem;
      margin: 0 0 10px 0;
    }

    h4 {
      font-weight: 400;
      font-size: 0.9rem;
      margin: 0 0 10px 0;
    }
  }
`;

export default Rules;
