import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";

//components
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { lv } from "date-fns/locale";
import moment from "moment";

//icons
import { CalendarCheck } from "@styled-icons/bootstrap/CalendarCheck";

//api
import { getAvailableAccommodations } from "../../api/api-list";

//manually disabled dates used when all the campsite is closed
import { manualDisabledDates } from '../../api/manual-disabled-dates';

//translation
import { useTranslation } from "react-i18next";

moment.locale("lv");

const SearchAvailability = () => {
  const { t, i18n } = useTranslation();

  //booking dates
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dataSet, setDataSet] = useState(false);
  const [selectedDatesCount, setSelectedDatesCount] = useState(0);
  const [bookedAccommodations, setBookedAcccommodations] = useState([]);
  const [filteredAccommodations, setFilteredAccommodations] = useState([]);

  const accommodations = [
    {
      id: 1,
      name: t("house_1_info_name"),
      desc: t("house_1_info_desc"),
      images: [
        {
          url: "../images/houses/1/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/1/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 45,
      persons: 4,
      link: "/houses/1",
    },
    {
      id: 2,
      name: t("house_2_info_name"),
      desc: t("house_2_info_desc"),
      images: [
        {
          url: "../images/houses/2/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/2/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/2",
    },
    {
      id: 3,
      name: t("house_3_info_name"),
      desc: t("house_3_info_desc"),
      images: [
        {
          url: "../images/houses/3/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/3/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/3",
    },
    {
      id: 4,
      name: t("house_4_info_name"),
      desc: t("house_4_info_desc"),
      images: [
        {
          url: "../images/houses/4/0.jpg",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/4/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/4",
    },
    {
      id: 5,
      name: t("house_5_info_name"),
      desc: t("house_5_info_desc"),
      images: [
        {
          url: "../images/houses/5/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/5/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/5",
    },
    {
      id: 6,
      name: t("house_6_info_name"),
      desc: t("house_6_info_desc"),
      images: [
        {
          url: "../images/houses/6/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/6/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 40,
      persons: 3,
      link: "/houses/6",
    },
    {
      id: 7,
      name: t("house_7_info_name"),
      desc: t("house_7_info_desc"),
      images: [
        {
          url: "../images/houses/7/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/7/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 40,
      persons: 3,
      link: "/houses/7",
    },
    {
      id: 8,
      name: t("house_8_info_name"),
      desc: t("house_8_info_desc"),
      images: [
        {
          url: "../images/houses/8/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/8/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 40,
      persons: 3,
      link: "/houses/8",
    },
    {
      id: 9,
      name: t("house_9_info_name"),
      desc: t("house_9_info_desc"),
      images: [
        {
          url: "../images/houses/9/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/9/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/9",
    },
    {
      id: 10,
      name: t("house_10_info_name"),
      desc: t("house_10_info_desc"),
      images: [
        {
          url: "../images/houses/10/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/10/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 70,
      persons: 6,
      link: "/houses/10",
    },
    {
      id: 11,
      name: t("house_11_info_name"),
      desc: t("house_11_info_desc"),
      images: [
        {
          url: "../images/houses/11/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/11/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/11",
    },
    {
      id: 12,
      name: t("house_12_info_name"),
      desc: t("house_12_info_desc"),
      images: [
        {
          url: "../images/houses/12/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/houses/12/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 35,
      persons: 2,
      link: "/houses/12",
    },
    {
      id: 13,
      name: t("house_13_info_name"),
      desc: t("house_13_info_desc"),
      images: [
        {
          url: "../images/tents/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/tents/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 10,
      persons: 2,
      link: "/tents",
    },
    {
      id: 14,
      name: t("house_14_info_name"),
      desc: t("house_14_info_desc"),
      images: [
        {
          url: "../images/trailers/0.png",
          class: "house-single-img-placeholder-main",
        },
        {
          url: "../images/trailers/1.png",
          class: "house-single-img-placeholder-second",
        },
      ],
      price: 15,
      persons: 2,
      link: "/trailers",
    },
  ];

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getAvailableHouses = () => {
    const isoStartDate = formatDate(new Date(startDate));
    const isoEndDate = formatDate(new Date(endDate));

    const apiUrl = `${getAvailableAccommodations}?start_date=${isoStartDate}&end_date=${isoEndDate}`; // Replace with your actual API endpoint URL

    // console.log(apiUrl);

    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setBookedAcccommodations(response.data);
        setDataSet(true);
      });
    // .catch((error) => {
    //   console.error('Error fetching available houses:', error);
    // });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (startDate && endDate) {
      if (startDate.getTime() === endDate.getTime()) {
        alert("Iebraukšanas un izbraukšanas datums nevar būt vienāds.");
        return false;
      } else {
        getAvailableHouses();
      }
    }
  };

  const calculateSelectedDatesCount = () => {
    const millisecondsInDay = 86400000; // Number of milliseconds in a day
    const daysDifference = (endDate - startDate) / millisecondsInDay;
    const newSelectedDatesCount = Math.abs(daysDifference); // Adding 1 to include both start and end dates
    setSelectedDatesCount(newSelectedDatesCount);
  };

  useEffect(() => {
    calculateSelectedDatesCount();

    //eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedDatesCount > 0) {
      getAvailableHouses();
    }

    //eslint-disable-next-line
  }, [selectedDatesCount]);

  useEffect(() => {
    if (bookedAccommodations?.length > 0) {
      const availableHouses = accommodations.filter(
        (house) => !bookedAccommodations.includes(house.id)
      );
      setFilteredAccommodations(availableHouses);
      // console.log(availableHouses);
    } else {
      setFilteredAccommodations(accommodations);
    }

    //eslint-disable-next-line
  }, [bookedAccommodations]);

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("availability_title")}</title>
      </Helmet>

      <div className="search-wrapper">
        {dataSet && (
          <>
            {selectedDatesCount > 0 ? (
              <>
                <div className="search-wrapper-data">
                  <div className="search-result-wrapper">
                    {filteredAccommodations?.map((house) => (
                      <Link
                        key={house.id}
                        to={house?.link}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <div key={house?.name} className="house-single">
                          <div className="house-single-img-wrapper">
                            {house?.images?.map((image) => (
                              <img
                                className={image?.class}
                                src={image?.url}
                                alt="house-img"
                              />
                            ))}
                          </div>

                          <div className="house-single-data">
                            <h3 className="house-single-data-name">
                              {house?.name}
                            </h3>
                            <p className="house-single-data-desc">
                              {house?.desc}
                            </p>
                            <p className="house-single-data-price">
                              €{house?.price}
                              <span> / {t("availability_p1")}</span>
                            </p>

                            <p className="house-single-data-price">
                              €{selectedDatesCount * house?.price}
                              <span>
                                {" "}
                                / {selectedDatesCount} {t("availability_p2")}
                              </span>
                            </p>
                          </div>

                          <div>
                            <div
                              to={house?.link}
                              className="house-single-reserve"
                            >
                              {t("availability_p3")}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className="search-wrapper-data">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 20,
                    paddingTop: 40,
                  }}
                >
                  <CalendarCheck
                    style={{
                      maxWidth: 32,
                      width: "100%",
                      color: "var(--green)",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "1rem",
                      color: "var(--green)",
                      textAlign: "center",
                    }}
                  >
                    {t("availability_p4")}
                  </p>
                </div>
              </div>
            )}
          </>
        )}

        <div
          style={{
            maxWidth: 450,
            width: "100%",
            display: "flex",
          }}
        >
          <form className="search-wrapper-calendar" onSubmit={handleSubmit}>
            <div className="reservation-item">
              <h3 className="reservation-heading">{t("availability_p5")}</h3>
              <div className="reservation-wrapper">
                <DateRangePicker
                  ranges={[selectionRange]}
                  weekStartsOn={1}
                  showMonthAndYearPickers={false}
                  locale={lv}
                  rangeColors={["var(--green)"]}
                  minDate={new Date()}
                  onChange={handleSelect}
                  disabledDates={manualDisabledDates}
                />
              </div>
            </div>

            {/* <div className="reservation-item">
              <button type="submit" className="create-reservation-btn">
                Meklēt
              </button>
            </div> */}
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;

  .search-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media only screen and (max-width: 960px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .search-wrapper-data {
      flex: 1;
      padding: 20px 0;

      .search-result-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 3%;
        padding: 0 20px;
        justify-content: flex-start;

        a {
          text-decoration: none;
          color: var(--black);
          cursor: pointer;
          display: flex;
          flex-direction: column;

          max-width: calc((100% - 3% * 3) / 3);
          min-width: calc((100% - 3% * 3) / 3);

          @media only screen and (max-width: 1280px) {
            max-width: calc(
              (100% - 3% * 1) / 2
            ); /* For two houses in one line */
            min-width: calc((100% - 3% * 1) / 2);
          }

          @media only screen and (max-width: 550px) {
            max-width: 100%; /* For one house in one line (full width) */
            min-width: 100%;
          }
        }

        @media only screen and (min-width: 700px) {
          .house-single:hover {
            .house-single-reserve {
              color: var(--white);
              background-color: var(--green);
            }

            .house-single-img-placeholder-second {
              display: block !important;
            }

            img {
              transform: scale(1.1);
            }
          }
        }

        .house-single {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          margin: 0 0 40px 0;
          flex: 1;
          justify-content: space-between;

          .house-single-reserve {
            border: 1px solid var(--green);
            padding: 8px 15px;
            max-width: unset;
            width: 100%;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;
            transition: 0.2s linear;
            font-size: 0.9rem;
            color: var(--green);
          }

          .house-single-data {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .house-single-data-name {
              font-size: 1.2rem;
            }

            .house-single-data-desc {
              font-size: 0.9rem;
              font-weight: 400;
            }

            .house-single-data-price {
              font-size: 1.2rem;
              font-weight: 700;
              color: var(--green);

              span {
                font-weight: 400;
                font-size: 0.9rem;
                color: var(--grey);
              }
            }
          }

          .house-single-img-wrapper {
            width: 100%;
            height: 100%;
            max-height: 185px;
            min-height: 185px;
            border-radius: 5px;
            position: relative;
            overflow: hidden;

            @media only screen and (max-width: 600px) {
              max-height: 225px;
              min-height: 225px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5px;
              transition: 0.2s linear;
            }

            .house-single-img-placeholder-second {
              display: none;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    .search-wrapper-calendar {
      max-width: 450px;
      width: 100%;
      margin: 40px 10px;
      position: sticky;
      top: 140px;

      @media only screen and (max-width: 960px) {
        position: relative;
        top: 0;
      }

      .reservation-item {
        .reservation-heading {
          font-weight: 500;
          font-size: 1.2rem;
          color: var(--green);
          margin: 40px 0;
          text-align: center;
        }

        .create-reservation-btn {
          cursor: pointer;
          color: var(--green);
          background-color: transparent;
          text-transform: uppercase;
          text-decoration: none;
          font-weight: 600;
          padding: 12px 15px;
          border-radius: 5px;
          outline: none;
          border: 1px solid var(--green);
          width: 100%;
          transition: 0.2s linear;
        }

        .create-reservation-btn:hover {
          color: var(--white);
          background-color: var(--green);
        }
      }
    }
  }

  .rdrDateRangePickerWrapper {
    width: 100%;

    .rdrDateDisplayWrapper {
      background-color: var(--lightgrey2);
    }

    .rdrDateDisplay {
      color: var(--green) !important;
    }

    .rdrCalendarWrapper {
      width: 100%;

      .rdrMonth {
        width: 100%;
      }
    }
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }
`;

export default SearchAvailability;
