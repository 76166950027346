import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Slider from "react-slick";

//translation
import { useTranslation } from "react-i18next";

//icons
import { PlusSquareDotted } from "@styled-icons/bootstrap/PlusSquareDotted";
import { Star } from "@styled-icons/fa-solid/Star";

const Reviews = () => {
  const { t, i18n } = useTranslation();

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviews = [
    {
      author_name: "Linda Stalmeistare",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjUcLZsU8JrWEAcWV2jrxxDgjW2uIJ9rVEjaQZMsluMsy8Jt1QihuQ=w60-h60-p-rp-mo-ba4-br100",
      rating: 5,
      text: "Perfekts kempings klusuma un miera meklētājiem, tur pat netālu no jūras. Viens no pirmajiem kempingiem Latvijā ar 26 gadu stāžu. Saimniekam ir savi noteikumi, kurus mēs ļoti novērtējām. Jauka, mājīga atmosfēra, tiešām miers un klusums, ko ne visur var atrast...",
    },
    {
      author_name: "Inna Mironova",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjXADAboWoRwcTqOhhnPvGjuoVnk1lrUcad8AJwrgu5CGxD7_W7x=w60-h60-p-rp-mo-ba4-br100",
      rating: 5,
      text: "MIERS un DABA, VISS👍❤️❤️❤️Atgriezos uz šejieni atkal augustā, un tā jau kādus gadus astoņus pēc kārtas. Pirmas dienas vienkārši izguļos, pieelpoju plaušas ar priežu un jūras smaržu. Tāpēc ļoti augstu novērtēju kempinga saimnieka nostāju ievērot klusumu. Mēs braucam atpūsties, nevis klausīties mūziku, kura iespējams nav manā gaumē. Ballīšu mīlētājiem jāmeklē cits piedāvājums.Šeit var atpūsties ar mājdzīvniekiem!",
    },
    {
      author_name: "Inese Cernuho",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjVGWK9cmIMN2LZyXNsZ5fC1JWNjYmuhV1KFnD86IJPAsOBAIQyJ=w60-h60-p-rp-mo-ba4-br100",
      rating: 5,
      text: "Lieliska atpūta pie jūras,tīra pludmale, klusums, ļoti viesmīlīgs saimnieks.",
    },
    {
      author_name: "Gatis Kampernovs",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjVa8WppZeXdk17AIQDrVjjXS1qOUi7-kxm-6f-ksZm2BaRcXJrj=w60-h60-p-rp-mo-br100",
      rating: 5,
      text: "Atsaucīgi saimnieki, plaša un sakopta teritorija. Palikām ilgāk kā plānojām",
    },
    {
      author_name: "Gaļina Lode",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/ACg8ocKFiA_tLMUQvk4y7uU2PTvefRNIEMe7TPmS1xnEUf_82Y_Hsw=w60-h60-p-rp-mo-ba3-br100",
      rating: 5,
      text: "Ļoti mierīga un skaista vieta. Kurš vēlas atpūsties no skaļiem pilsētas trokšņiem, šī ir ideāla vieta mierīgam relaksam. Vienkārši brīnišķīga vieta atpūtai pie dabas mierīgiem cilvēkiem un ir padomāts arī par bērniem 🙂",
    },
    {
      author_name: "Agija Freiberga",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjXYTCxD6GIj6dVXQ0kj1S3PQjPq5WMlHSTJQC4RdHxlYxMVR23G=w60-h60-p-rp-mo-br100",
      rating: 5,
      text: "Vieta, kur katru vasaru atgriežamies jau 20 gadus.",
    },
    {
      author_name: "Laila Rudzīte",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjVtRmreR8YxrEaipgnqx1T7TNpwRJOTbMp5vcf5WR6Xv-NdjI_T=w60-h60-p-rp-mo-ba3-br100",
      rating: 5,
      text: "Burvīgi, super saimnieks.",
    },
    {
      author_name: "Justas Gavėnas",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjXPrx0c-_mvuVsXIATCwfYuhLf8hPXkruRcjsxKaoWXfzE-z9kH=w60-h60-p-rp-mo-ba4-br100",
      rating: 5,
      text: "Stayed several nights. 5 minutes to the sea. Very calm and silent. The owner on purpose locates visitors in a distance from each other. Toilets clean, electricity available, no noise from a main street. No kitchen but ideal with a camper van. No minishop or kiosk so have own food & drinks. Fireplaces available",
    },
    {
      author_name: "Ilja Trifonovs",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjVtyTJ6Dp8yyLOQYkHdeWPMnoGwPuljTJUMLXlXOKRMLw-iffkHQw=w60-h60-p-rp-mo-ba3-br100",
      rating: 4,
      text: "Nice campsite. Very quiet. There is a table and a place for making fire next to tent place. Solar heated shower is free of charge. Hot shower costs a couple of euros. There are a couple of toilets that might need a bit of renovation. Owner is super nice and welcoming. Beach is a 5 min walk. I recommend this place!",
    },
    {
      author_name: "Ruta Rastene",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjVEZvtoIIsSAFuA8mQ2kO1RODohqIgoHZr3W3L7ov7dMPd1qYUakA=w60-h60-p-rp-mo-ba4-br100",
      rating: 5,
      text: "Highly reccomend it if you look for something authentic and cheap to stay and chill",
    },
    {
      author_name: "Mārtiņš Prūsis",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjW-lH30Y7_y5zICfzSvBFcYZPGvWYuIUImnupnHhZY8z_loRfVynQ=w60-h60-p-rp-mo-br100",
      rating: 5,
      text: "Great place if you prefer a quite camping place! Returning already for 15+ years.",
    },
    {
      author_name: "Kęstutis Mažeika",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjXVk8XtAQponKdz4W3ztJb_9d_FATjfEyNtK7aRZ2UMjRfc7pOaiw=w60-h60-p-rp-mo-ba5-br100",
      rating: 3,
      text: "Quite camping for short stay, close to the sea, barbecue place, warm host.",
    },
    {
      author_name: "Daniel Gueffroy",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/ACg8ocLM-9JHerU2n-Qvtgs_YdLPdYuji3G7Zj0DOXiYcb7_0CXvgA=w60-h60-p-rp-mo-br100",
      rating: 5,
      text: "Ļoti jauks kempinga personāls - izpalīdzīgs un draudzīgs. Kad bija negaiss, dabūju sausu vietu, elektrību, ūdeni, viss bija brīnišķīgi.",
    },
    {
      author_name: "Aidas VvV",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjVGVCg9XKuZ1DOiZzMaiL0rLOVEzEstXKIrAZYKwfKdtuJcix2N=w60-h60-p-rp-mo-ba5-br100",
      rating: 5,
      text: "Ļoti labs kempings, laba vieta visai ģimenei, ūdens tuvumā, dušas un tualetes uzturētas. Saimnieks ļoti aizsargā un par visu rūpējas.",
    },
    {
      author_name: "Signe Ozoliņa",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjXAHtYJzjIL8im_bnWWtlIlsbWHbpYVPX6_p1cYjXVcISklCQs1=w60-h60-p-rp-mo-br100",
      rating: 5,
      text: "Noteikti iesaku",
    },
    {
      author_name: "Roberts Kreicbergs",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/ACg8ocLDryVuxGTYsppKxsTPHkRRqX71MsWQkrNXquhgbB0kDcnK=w60-h60-p-rp-mo-br100",
      rating: 5,
      text: "Vasaras galvaspilsēta. Atsaucīgs saimnieks.",
    },
    {
      author_name: "Madara Spriedēja",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjWb0gSCBz-2kjWeC_G9BN1ogqY1XX70DhzNPpETXBLFLu11xKk=w60-h60-p-rp-mo-ba3-br100",
      rating: 5,
      text: "Atsaucīgi saimnieki, piemeklēs namiņu pēc jūsu vēlmēm (tikai vēlams laicīgi pieteikt). Ir tualetes, dušas telpa, grils utt. Jūra tuvu!",
    },
    {
      author_name: "Laura",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjWt5cSwj4e79AYtt_rNjKuffIkwh02JR8Sww3vJnjdOMWLiwc8=w60-h60-p-rp-mo-ba4-br100",
      rating: 5,
      text: "Beautiful place!",
    },
    {
      author_name: "Indra Ķīse",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/ACg8ocKZnpnnGOcVrumNXS6LaVILeHDn92dPNgIsSpznRZuM6lbZSw=w60-h60-p-rp-mo-br100",
      rating: 5,
      text: "Ļoti jauks, kluss kempings. Laipni saimnieki.",
    },
    {
      author_name: "Iveta Vanaga",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/ACg8ocJSO6rhHisvoQ-3hLrwMKpzvJs-fg1fSSr-6682uEaVY0G9Hw=w60-h60-p-rp-mo-br100",
      rating: 5,
      text: "A great place to relax. I really liked everything. Silence and peace, where lovers (musicians, music lovers) can disconnect from empty noise.",
    },
    {
      author_name: "Ieva Vicinska",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/ACg8ocLDST0a4s4F7JdACjSRFikkkVHR-t8soymA92gEMEL6Obu8Kg=w60-h60-p-rp-mo-ba3-br100",
      rating: 5,
      text: "Plaša teritorija, atsaucīgi saimnieki. Ir ugunskura vietas un galdi piknikam.",
    },
  ];

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <div className="heading">
        <div>
          <h3>{t("reviews_h3")}</h3>
        </div>
        <Link
          to="https://search.google.com/local/writereview?placeid=ChIJN8ykGd4R7kYRuJ7q2nyCnfk"
          target="_blank"
          rel="norel"
          className="btn-dekst"
        >
          <p>{t("reviews_p")}</p>
          <PlusSquareDotted className="icon" />
        </Link>
      </div>

      <div className="review-list">
        <Slider {...settings}>
          {reviews?.map((r) => (
            <Review key={r?.author_name} className="review">
              <div className="review-top">
                <img src={r?.profile_photo_url} alt="user-img" />
                <div className="review-top-right">
                  <p className="review-author">{r?.author_name}</p>
                  <div className="review-rating">
                    <Star className="icon" />
                    <p>{r?.rating}</p>
                  </div>
                </div>
              </div>

              <div className="review-bottom">
                <p className="review-text">{r?.text}</p>
              </div>
            </Review>
          ))}
        </Slider>
      </div>

      <Link
        to="https://search.google.com/local/writereview?placeid=ChIJN8ykGd4R7kYRuJ7q2nyCnfk"
        target="_blank"
        rel="norel"
        className="btn-mobile"
      >
        <p>{t("reviews_p")}</p>
        <PlusSquareDotted className="icon" />
      </Link>
    </Wrapper>
  );
};

{
  /* <div className="gallery-placeholder">
  <div className="gallery-placeholder-top">
    <h3> {t("gallery")}</h3>
  </div>

  <div className="gallery-placeholder-bottom">
    <Link to="/gallery">
      <p>{t("view")}</p>
    </Link>
  </div>
</div> */
}

const Review = styled.div`
  margin: 0 0 40px 0;
  padding: 0 10px;

  .review-top {
    display: flex;
    gap: 20px;
    margin: 0 0 20px 0;

    .review-top-right {
      .review-rating {
        display: flex;
        gap: 10px;
        align-items: center;

        .icon {
          max-width: 24px;
          min-width: 24px;
          width: 100%;
          color: var(--green);
        }

        p {
          font-size: 1.2rem;
          color: var(--green);
          font-weight: 600;
        }
      }

      .review-author {
        font-size: 1rem;
        font-weight: 600;
        margin: 0 0 5px 0;
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .review-bottom {
    .review-text {
      font-size: 0.9rem;
      line-height: 1.6;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0px auto;
  position: relative;
  overflow: hidden;

  .slick-dots li.slick-active button:before {
    color: var(--green);
  }

  .slick-dots {
    position: absolute;
    bottom: -20px;

    @media (min-width: 760px) {
      bottom: 0;
    }
  }

  /* .slick-dots {
    height: 60px;
  } */

  .heading {
    margin: 20px 0 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    h3 {
      font-size: 1.6rem;
      margin: 0 0 10px 0;
    }

    .btn-dekst {
      text-decoration: none;
      color: var(--white);
      background-color: var(--green);
      border-radius: 5px;
      padding: 8px 15px;
      font-size: 0.9rem;
      font-weight: 600;
      display: flex;
      align-items: center;

      .icon {
        min-width: 24px;
        max-width: 24px;
        width: 100%;
        margin: 0 0 0 10px;
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
      }

      @media (max-width: 508px) {
        display: none;
      }
    }

    a:hover {
      opacity: 0.8;
    }
  }

  .btn-mobile {
      text-decoration: none;
      color: var(--white);
      background-color: var(--green);
      border-radius: 5px;
      padding: 8px 15px;
      font-size: 0.9rem;
      font-weight: 600;
      display: none;
      align-items: center;
      justify-content: center;
      margin: 40px 20px 0;

      .icon {
        min-width: 24px;
        max-width: 24px;
        width: 100%;
        margin: 0 0 0 10px;
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
      }

      @media (max-width: 508px) {
        display: flex;
      }
    }

    a:hover {
      opacity: 0.8;
    }
  }
`;

export default Reviews;
