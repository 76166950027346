import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

//api
import { logInDashboardApi } from "../../api/api-list";

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    overflow: hidden; /* Prevent scrolling */
  }

  #root {
    height: 100%; /* Ensure the root element takes full height */
  }
`;

const Auth = () => {
  const navigate = useNavigate();

  const variants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();

    if (username?.length > 0 && password?.length > 0) {
      axios
        .post(
          `${logInDashboardApi}`,
          { username, password },
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const { token, token_expires_at } = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("token_expires_at", token_expires_at);
          navigate("/admin");
        })
        .catch((error) => {
          // Handle error
          setError(error?.response?.data?.error);
          // console.log(error);
        });
    } else {
      setError("Lūdzu ievadi lietotājvārdu un paroli.");
    }
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <motion.div
          className="auth-wrapper"
          initial="hidden"
          animate="visible"
          variants={variants}
        >
          <img src="/images/logo-camp.png" alt="campsite-logo" />
          <>
            <h3>Sveicināts atpakaļ, </h3>
            <p className="login-description">Lūdzu ievadi detaļas lai ienāktu</p>

            <form onSubmit={handleLogin}>
              {error && (
                <>
                  <div className="error-container">
                    <p>{error}</p>
                  </div>
                </>
              )}

              <div className="input-container">
                <input
                  type="text"
                  placeholder="Lietotājvārds"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="input-container">
                <input
                  type="password"
                  placeholder="Parole"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button type="submit" className="auth-btn">
                <p>Ienākt</p>
              </button>
            </form>
          </>
        </motion.div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
height: 100vh;
width: 100vw;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0 20px;
overflow: hidden; /* Prevent scrolling */
background-image: url('/images/gallery/41.jpg');
background-repeat: no-repeat;
background-size: cover;
background-position: center bottom; /* Center the image */
background-attachment: fixed; /* Create a parallax effect */

  .auth-wrapper {
    text-align: center;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid var(--lightgrey);
    border-radius: 5px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white); /* Ensure a background color */

    @media only screen and (max-width: 700px) {
      box-shadow: none;
      border: none;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 20px 0 0 0;

      .error-container {
        width: 100%;
        padding: 12px 15px;
        border: 1px solid var(--red);
        border-radius: 5px;

        p {
          color: var(--red);
          text-align: center;
        }
      }

      .input-container {
        width: 100%;

        input {
          width: 100%;
          outline: none;
          border: 1px solid var(--white);
          padding: 12px 15px;
          border-radius: 5px;
          font-size: 0.9rem;
          background-color: var(--lightgrey2);
        }
      }
    }

    .auth-btn {
      cursor: pointer;
      color: var(--white);
      background-color: var(--green);
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 500;
      padding: 12px 15px;
      border-radius: 5px;
      outline: none;
      border: 1px solid var(--green);
      width: 100%;
      transition: 0.2s linear;
    }

    .auth-btn:hover {
      opacity: 0.7;
    }

    p {
      font-size: 0.9rem;
    }

    .login-description {
      color: var(--grey);
    }

    h3 {
      font-size: 1.1rem;
    }

    img {
      max-width: 125px;
      margin: 0 0 20px 0;
    }
  }
`;

export default Auth;
