import React, { useEffect } from "react";
import styled from "styled-components";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

//translation
import { useTranslation } from "react-i18next";

const Activities = () => {
  const [emblaRef] = useEmblaCarousel(
    {
      loop: true,
      autoplay: true,
      autoplaySpeed: 500,
      align: "start",
      slidesToScroll: 1,
      slidesToShow: 2,
      speed: 5,
    },
    [Autoplay()]
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <div className="heading">
        <h3>{t("activity_h")}</h3>
        <p>{t("activity_p")}</p>
      </div>

      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide">
            <img src="../images/activities/0.png" alt="slide-img" />
            <div className="slide-desc">
              <p>{t("activity_1")}</p>
            </div>
          </div>
          <div className="embla__slide">
            <img src="../images/activities/1.png" alt="slide-img" />
            <div
              className="slide-desc"
              style={{ backgroundColor: "var(--lightgreen)" }}
            >
              <p style={{ color: "var(--green)" }}>{t("activity_2")}</p>
            </div>
          </div>
          <div className="embla__slide">
            <img src="../images/activities/2.png" alt="slide-img" />
            <div
              className="slide-desc"
              style={{ backgroundColor: "var(--whitesmoke)" }}
            >
              <p style={{ color: "var(--green)" }}>{t("activity_3")}</p>
            </div>
          </div>
          <div className="embla__slide">
            <img src="../images/activities/3.png" alt="slide-img" />
            <div
              className="slide-desc"
              style={{ backgroundColor: "var(--lightgrey)" }}
            >
              <p style={{ color: "var(--green)" }}>{t("activity_4")}</p>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;

  @media only screen and (max-width: 1260px) {
    padding: 20px 0;
  }

  .embla {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
  }
  .embla__slide {
    flex: 0 0 50%;
    height: 400px;
    min-width: 0;
    position: relative;

    @media only screen and (max-width: 700px) {
      flex: 0 0 100%;
    }

    .slide-desc {
      position: absolute;
      bottom: 0;
      z-index: 80;
      width: 100%;
      padding: 20px;
      background-color: var(--green);

      p {
        font-size: 1rem;
        color: var(--white);
        font-weight: 500;
        margin: 0 auto;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .heading {
    margin: 20px 0;
    padding: 20px;

    h3 {
      font-size: 1.6rem;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
`;

export default Activities;
