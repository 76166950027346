import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//map
import MapBtn from "./MapBtn";

//translation
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <div className="row row-data">
        <div className="col">
          <img src="../images/logo-camp.png" alt="camp-logo" />

          <p>{t("footer_p1")}</p>

          <p>Plaucaki, Pūrciems, LV-3264, Latvija</p>
        </div>

        <div className="col">
          <h3>{t("footer_p2")}</h3>
          <div>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              {t("home")}
            </Link>
          </div>
          <div>
            <Link to="/pricing" onClick={() => window.scrollTo(0, 0)}>
              {t("pricing")}
            </Link>
          </div>
          <div>
            <Link to="/gallery" onClick={() => window.scrollTo(0, 0)}>
              {t("gallery")}
            </Link>
          </div>
          <div>
            <Link to="/map" onClick={() => window.scrollTo(0, 0)}>
              {t("map")}
            </Link>
          </div>
        </div>

        <div className="col">
          <h3>{t("campsite")}</h3>
          <div>
            <Link to="/houses" onClick={() => window.scrollTo(0, 0)}>
              {t("houses")}
            </Link>
          </div>
          <div>
            <Link to="/tents" onClick={() => window.scrollTo(0, 0)}>
              {t("tents")}
            </Link>
          </div>
          <div>
            <Link to="/trailers" onClick={() => window.scrollTo(0, 0)}>
              {t("trailers")}
            </Link>
          </div>
        </div>

        <div className="col">
          <h3>{t("information")}</h3>
          <div>
            <Link to="/rules" onClick={() => window.scrollTo(0, 0)}>
              {t("footer_p3")}
            </Link>
          </div>
          <div>
            <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>
              {t("footer_p4")}
            </Link>
          </div>
          {/* <div>
            <Link to="/">Rezervēšanas noteikumi</Link>
          </div> */}
        </div>
      </div>

      <div className="row row-copyright">
        <p>{`${t("footer_p5")} "Plaucaki", 2024.`}</p>
        <p>
          <Link to="mailto:ansisarturs@gmail.com">
            {`${t("footer_p6")} Ansis I.`}
          </Link>
        </p>
      </div>

      <MapBtn />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 60px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .row-copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    p {
      font-size: 0.8rem;
      color: var(--black);
    }

    a {
      color: var(--black);
      text-decoration: none;
    }

    a:hover {
      border-bottom: 1px solid var(--green);
    }
  }

  .row-data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;

    .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      min-width: 170px;

      a {
        text-decoration: none;
        color: var(--black);
        font-weight: 400;
        font-size: 0.9rem;
      }

      a:hover {
        border-bottom: 1px solid var(--green);
      }

      h3 {
        font-weight: 600;
        font-size: 1rem;
        margin: 0 0 5px 0;
      }

      p {
        font-size: 0.9rem;
        color: var(--black);
        max-width: 350px;
        margin: 0 0 20px 0;
      }

      img {
        max-width: 130px;
        object-fit: cover;
        margin: 0 0 20px 0;
      }
    }
  }
`;

export default Footer;
