import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Link } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";

const RandomHouseSuggestions = ({ houses }) => {
  console.log(houses);
  const { t, i18n } = useTranslation();

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <h3 className="heading">{t("we_recommend_h3")}</h3>
      <div className="slider-container">
        <Slider {...settings}>
          {houses?.map((house) => (
            <div key={house?.name}>
              <div className="house-wrapper">
                <div key={house?.name} className="house-single">
                  <div className="house-single-img-wrapper">
                    {house?.images?.map((image) => (
                      <img
                        className={image?.class}
                        src={image?.url}
                        // alt="house-img"
                      />
                    ))}
                  </div>

                  <div className="house-single-data">
                    <h3 className="house-single-data-name">{house?.name}</h3>
                    <p className="house-single-data-desc">{house?.desc}</p>
                    <p className="house-single-data-price">
                      €{house?.price}
                      <span> / {t("houses_p1")}</span>
                    </p>
                  </div>

                  <div>
                    <Link
                      to={house?.link}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <div to={house?.link} className="house-single-reserve">
                        {t("houses_p2")}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .slick-dots li.slick-active button:before {
    color: var(--green);
  }

  .heading {
    color: var(--black);
    font-size: 1.2rem;
    margin: 25px 0;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (min-width: 700px) {
    .house-single:hover {
      .house-single-reserve {
        color: var(--white);
        background-color: var(--green);
      }

      .house-single-img-placeholder-second {
        display: block !important;
      }

      img {
        transform: scale(1.1);
      }
    }
  }

  .house-wrapper {
    margin: 0 20px 60px 20px;
    display: "flex";
    height: 400px;

    @media only screen and (max-width: 700px) {
      height: 450px!important;
    }
  }

  .house-single {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    height: 100%;

    .house-single-reserve {
      border: 1px solid var(--green);
      padding: 8px 15px;
      max-width: unset;
      width: 100%;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.2s linear;
      font-size: 0.9rem;
      color: var(--green);
    }

    .house-single-data {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .house-single-data-name {
        font-size: 1.2rem;
      }

      .house-single-data-desc {
        font-size: 0.9rem;
        font-weight: 400;
      }

      .house-single-data-price {
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--green);

        span {
          font-weight: 400;
          font-size: 0.9rem;
          color: var(--grey);
        }
      }
    }

    .house-single-img-wrapper {
      width: 100%;
      height: 100%;
      max-height: 185px;
      min-height: 185px;
      border-radius: 5px;
      position: relative;
      overflow: hidden;

      @media only screen and (max-width: 600px) {
        max-height: 225px;
        min-height: 225px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        transition: 0.2s linear;
      }

      .house-single-img-placeholder-second {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`;

export default RandomHouseSuggestions;
