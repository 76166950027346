import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";

//icons
import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";
import { Camera } from "@styled-icons/bootstrap/Camera";

const Gallery = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <div className="heading">
        <h3>{t("gallery")}</h3>
        {/* <p>{t("activity_p")}</p> */}
        <Link to="/gallery" className="btn" onClick={() => window.scrollTo(0, 0)}>
          <p>{t("view")}</p>
          <ArrowRightShort className="icon" />
        </Link>
      </div>

      <div className="gallery-images">
        <Link to="/gallery" className="gallery-image" onClick={() => window.scrollTo(0, 0)}>
          <Camera className="gallery-icon" />
          <img src="../images/gallery/32.jpg" alt="gallery-img" />
        </Link>

        <Link to="/gallery" className="gallery-image" onClick={() => window.scrollTo(0, 0)}>
          <Camera className="gallery-icon" />
          <img src="../images/gallery/30.jpg" alt="gallery-img" />
        </Link>

        <Link to="/gallery" className="gallery-image" onClick={() => window.scrollTo(0, 0)}>
          <Camera className="gallery-icon" />
          <img src="../images/gallery/39.jpg" alt="gallery-img" />
        </Link>

        <Link to="/gallery" className="gallery-image" onClick={() => window.scrollTo(0, 0)}>
          <Camera className="gallery-icon" />
          <img src="../images/gallery/41.jpg" alt="gallery-img" />
        </Link>
      </div>
    </Wrapper>
  );
};

{
  /* <div className="gallery-placeholder">
  <div className="gallery-placeholder-top">
    <h3> {t("gallery")}</h3>
  </div>

  <div className="gallery-placeholder-bottom">
    <Link to="/gallery">
      <p>{t("view")}</p>
    </Link>
  </div>
</div> */
}
const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0px auto;
  position: relative;
  overflow: hidden;

  .heading {
    margin: 20px 0 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 1.6rem;
      margin: 0 0 10px 0;
    }

    .btn {
      text-decoration: none;
      color: var(--white);
      background-color: var(--green);
      border-radius: 5px;
      padding: 8px 15px;
      font-size: 0.9rem;
      font-weight: 600;
      display: flex;
      align-items: center;

      .icon {
        min-width: 24px;
        max-width: 24px;
        width: 100%;
        margin: 0 0 0 10px;
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }

    a:hover {
      opacity: 0.8;
    }
  }

  .gallery-images {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }

  .gallery-image {
    height: 300px;
    flex-grow: 1;
    flex-basis: calc(25% - 10px); /* 4 columns with gap */
    max-width: calc(25% - 10px);
    overflow: hidden;
    text-decoration: none;
    color: var(--white);
    position: relative;
    transition: flex-basis 0.3s ease-in-out, max-width 0.3s ease-in-out;
  }

  .gallery-image .gallery-icon {
    min-width: 28px;
    max-width: 28px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s linear;
    z-index: 10;
  }

  .gallery-image:hover img {
    transform: scale(1.1);
  }

  .gallery-image:hover .gallery-icon {
    opacity: 1;
  }

  .gallery-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.2s linear;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .gallery-image {
      flex-basis: calc(50% - 5px); /* 2 columns */
      max-width: calc(50% - 5px);
    }
  }

  @media (max-width: 480px) {
    .gallery-images {
      gap: 0;

      .gallery-image {
        flex-basis: calc(100%); /* 1 column */
        max-width: calc(100%);

        .gallery-icon {
          opacity: 1;
        }
      }
    }
  }
`;

export default Gallery;
