import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

//api
import {
  getAllBookingsDashboard,
  updateReservationStatusApi,
  updateReservationPriceApi,
  deleteReservationApi,
  updateReservationApi,
} from "../../api/api-list";

//components
import NavBar from "../components/NavBar";
import NavBarMobile from "../components/NavBarMobile";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/lv";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import { lv } from "date-fns/locale";
import { format } from "date-fns";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

//icons
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import { Save } from "@styled-icons/boxicons-regular/Save";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { Today } from "@styled-icons/material-rounded/Today";
import { KeyboardArrowLeft } from "@styled-icons/material/KeyboardArrowLeft";
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";
import { House } from "@styled-icons/fa-solid/House";
import { Tent } from "@styled-icons/fluentui-system-filled/Tent";
import { Trailer } from "@styled-icons/fa-solid/Trailer";
import { Plus } from "@styled-icons/evaicons-solid/Plus";
import { PhoneCall } from "@styled-icons/boxicons-regular/PhoneCall";
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline";

import { motion } from "framer-motion";
import { manualDisabledDates } from "../../api/manual-disabled-dates";

//hooks
import useAuth from "../hooks/useAuth";

moment.locale("lv");

const Reservations = () => {
  const localizer = momentLocalizer(moment);

  //auth
  const navigate = useNavigate();
  const auth = useAuth();

  const variants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  //confetti
  const { width, height } = useWindowSize();

  //selected reservation data
  const [selectedEventData, setSelectedEventData] = useState("");
  const [reservationStatus, setReservationStatus] = useState("");
  const [reservationPrice, setReservationPrice] = useState("");
  const [disabledDates, setDisabledDates] = useState([]);

  //calendar stuff
  const CustomEvent = ({ event }) => {
    let eventName;

    if (event.accommodationId < 13) {
      eventName = `${event.accommodationId}. mājiņa`;
    } else if (event.accommodationId >= 13 && event.accommodationId < 14) {
      eventName = `Telts vieta`;
    } else if (event.accommodationId >= 14) {
      eventName = `Treilera vieta`;
    }

    let backgroundColor;

    if (event.status === "Pending") {
      backgroundColor = "dimgrey";
    } else if (event.status === "Confirmed") {
      backgroundColor = "var(--blue)";
    } else if (event.status === "Completed") {
      backgroundColor = "var(--green)";
    }

    return (
      <CalendarEvent
        style={{ backgroundColor: backgroundColor }}
        onClick={() => {
          setModal(true);
          // console.log(event);
          setSelectedEventData(event);
          setStartDate(new Date(event?.start));
          setEndDate(new Date(event?.end));
        }}
      >
        <h3>{eventName}</h3>
        <p>{event.name}</p>
      </CalendarEvent>
    );
  };

  const CustomToolbar = ({ label, onNavigate }) => (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group" style={{ display: "flex" }}>
        <button
          type="button"
          style={{ flex: "1 0 0" }}
          onClick={() => onNavigate("TODAY")}
        >
          <Today className="icon" />
        </button>
        <button
          type="button"
          style={{ flex: "1 0 0" }}
          onClick={() => onNavigate("PREV")}
        >
          <KeyboardArrowLeft className="icon" />
        </button>
        <button
          type="button"
          style={{ flex: "1 0 0" }}
          onClick={() => onNavigate("NEXT")}
        >
          <KeyboardArrowRight className="icon" />
        </button>
      </span>
      <span className="rbc-toolbar-label">{label}</span>
    </div>
  );

  const campsiteClosedDays = [new Date(2024, 5, 28), new Date(2024, 6, 24)];

  const dayPropGetter = (date) => {
    const isMarked = campsiteClosedDays.some(
      (markedDate) => markedDate.toDateString() === date.toDateString()
    );

    if (isMarked) {
      return {
        style: {
          backgroundColor: "var(--red)",
          color: "white",
        },
      };
    }
  };

  const [reservations, setReservations] = useState([]);
  const [allReservations, setAllReservations] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState("houses");

  //calendar stuff -- end

  //current date

  const [date, setDate] = useState(null);

  const getCurrentDate = () => {
    const daysOfWeek = [
      "Svētdiena",
      "Pirmdiena",
      "Otrdiena",
      "Trešdiena",
      "Ceturtdiena",
      "Piektdiena",
      "Sestdiena",
    ];

    const today = new Date();
    const dayOfWeek = daysOfWeek[today.getDay()];
    const dayOfMonth = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();

    setDate(`${dayOfWeek}, ${dayOfMonth}.${month}.${year}.`);
  };

  //current date -- end

  const getBookingData = () => {
    setReservations([]);
    const apiUrl = `${getAllBookingsDashboard}`;

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        // console.log(response.data);

        const modifiedData = response.data.map((item) => {
          return {
            accommodationId: item.accommodation_id,
            bookingId: item.booking_id,
            start: item.check_in_date,
            end: item.check_out_date,
            createdAt: item.created_at,
            email: item.email,
            name: item.name,
            notes: item.notes,
            persons: item.persons,
            phoneNumber: item.phone_number,
            status: item.status,
            totalPrice: item.total_price,
          };
        });

        let modifiedDataFiltered;

        if (selectedCalendar === "houses") {
          modifiedDataFiltered = modifiedData.filter(
            (reservation) => reservation?.accommodationId < 13
          );
        } else if (selectedCalendar === "tentsTrailers") {
          modifiedDataFiltered = modifiedData.filter(
            (reservation) => reservation?.accommodationId > 12
          );
        }

        setAllReservations(modifiedData);
        setReservations(modifiedDataFiltered);
      });
  };

  //modal stuff

  const [modal, setModal] = useState(false);

  //reservation status update
  const handleSelectStatus = (event) => {
    const selectedValue = event.target.value;
    setReservationStatus(selectedValue);
  };

  const updateReservationStatus = (bookingId, status) => {
    const formData = {
      bookingId: parseInt(bookingId),
      status: status,
    };

    // Send reservation data to backend API
    axios
      .post(updateReservationStatusApi, formData, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("updated");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //reservation status update -- end

  //reservation price update
  const handleSelectPrice = (event) => {
    const selectedValue = event.target.value;
    setReservationPrice(selectedValue);
  };

  const updateReservationPrice = (id, reservationPrice) => {
    const formData = {
      bookingId: parseInt(id),
      totalPrice: reservationPrice,
    };

    // Send reservation data to backend API
    axios
      .post(updateReservationPriceApi, formData, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("updated price!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //reservation price update -- end

  const updateReservationData = (bookingId) => {
    updateReservationStatus(bookingId, reservationStatus);
    updateReservationPrice(bookingId, reservationPrice);

    setTimeout(() => {
      setModal(false);
      getBookingData();
    }, 500);
  };

  const [showConfetti, setShowConfetti] = useState(false);

  const notifyUpdate = (status) => {
    if (status) {
      if (status === "Completed") {
        setShowConfetti(true);

        setTimeout(() => {
          setShowConfetti(false);
        }, [5000])
      }
    }

    toast("Rezervācijas dati ir atjaunoti! 🤘");
  };

  const updateReservationDataNew = (id) => {
    const formatDate = (date) => {
      return format(date, "yyyy-MM-dd");
    };

    const formData = {
      bookingId: parseInt(id),
      accommodationId: parseInt(selectedEventData?.accommodationId), // Assuming `id` is the ID of the selected accommodation
      persons: parseInt(selectedEventData?.persons),
      checkInDate: formatDate(startDate),
      checkOutDate: formatDate(endDate),
      status: selectedEventData?.status,
      totalPrice: selectedEventData?.totalPrice,
      name: selectedEventData?.name,
      email: selectedEventData?.email,
      phoneNumber: selectedEventData?.phoneNumber,
      notes: selectedEventData?.notes,
    };

    // Send reservation data to backend API
    axios
      .post(updateReservationApi, formData, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTimeout(() => {
          setModal(false);
          notifyUpdate(formData?.status);
          getBookingData();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const notifyDeletion = () => toast("Rezervācija tika izdzēsta! 🤘");

  //reservation delete
  const deleteReservation = (id) => {
    // Include the booking ID in the URL
    const url = `${deleteReservationApi}/${id}`;

    axios
      .delete(url, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        notifyDeletion();
        setModal(false);
        getBookingData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //booking dates
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  //modal stuff -- end

  // useEffect(() => {
  //   console.log(selectedEventData);
  // }, [selectedEventData]);

  useEffect(() => {
    if (auth !== null) {
      if (!auth) {
        navigate("/admin/login");
      } else {
        getCurrentDate();
        getBookingData();
      }
    }

    //eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    if (selectedEventData) {
      setReservationPrice(selectedEventData?.totalPrice);
      setReservationStatus(selectedEventData?.status);
    }
  }, [selectedEventData]);

  useEffect(() => {
    const readableDates = manualDisabledDates.map((isoDate) => {
      const date = new Date(isoDate);
      const day = date.getUTCDate() + 1;
      const month = date.getUTCMonth() + 1; // Months are zero-indexed, so add 1
      const year = date.getUTCFullYear();
      return `${day}.${month}.${year}`; // Formats to "date.month.year"
    });

    setDisabledDates(readableDates.join(", "));
  }, []);

  useEffect(() => {
    if (allReservations) {
      const modifiedData = allReservations;

      let modifiedDataFiltered;

      if (selectedCalendar === "houses") {
        modifiedDataFiltered = modifiedData.filter(
          (reservation) => reservation?.accommodationId < 13
        );
      } else if (selectedCalendar === "tentsTrailers") {
        modifiedDataFiltered = modifiedData.filter(
          (reservation) => reservation?.accommodationId > 12
        );
      }

      setReservations(modifiedDataFiltered);
    }
    //eslint-disable-next-line
  }, [selectedCalendar]);

  return (
    <Wrapper>
      {showConfetti && (
        <>
          <Confetti width={width} height={height} className="confetti" />
        </>
      )}

      <NavBarMobile />
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <Sticky>
          <NavBar />
          <RouterAndTime>
            <div className="router-time-container">
              <div className="heading-router">
                <p>Vadība</p>
                <Link to="/admin/calendar">/ Kalendārs</Link>
              </div>

              <div className="heading-date">
                <p>{date}</p>
              </div>
            </div>
          </RouterAndTime>
        </Sticky>
        <Dash>
          <DashLeft>
            <div className="content">
              <CalendarHeading>
                <h3>Kalendārs</h3>

                <div className="calendar-colors">
                  <p className="color-heading">Kalendāra marķējums:</p>

                  <div className="calendar-colors-list">
                    <div className="calendar-color">
                      <div
                        className="calendar-color-circle"
                        style={{ backgroundColor: "dimgrey" }}
                      />
                      <p>
                        Neapstiprinātas rezervācijas (veikta rezervācija,
                        klients nav devis ziņu par ierašanos)
                      </p>
                    </div>

                    <div className="calendar-color">
                      <div
                        className="calendar-color-circle"
                        style={{ backgroundColor: "var(--blue)" }}
                      />
                      <p>
                        Apstiprinātas rezervācijas (klients ir sniedzis ziņu par
                        savu ierašanos)
                      </p>
                    </div>

                    <div className="calendar-color">
                      <div
                        className="calendar-color-circle"
                        style={{ backgroundColor: "var(--green)" }}
                      />
                      <p>
                        Pabeigtas/noslēgtas rezervācijas (klients ir veicis
                        maksājumu)
                      </p>
                    </div>

                    {disabledDates && (
                      <>
                        <div className="calendar-color">
                          <div
                            className="calendar-color-circle"
                            style={{ backgroundColor: "var(--red)" }}
                          />
                          <p>
                            Dienas, kad kempings tiek slēgts: {disabledDates}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </CalendarHeading>

              <CalendarSwitch>
                <CalendarSwitchBtns>
                  <CalendarSwitchBtn
                    onClick={() => setSelectedCalendar("houses")}
                    className={selectedCalendar === "houses" && "active"}
                  >
                    <div>
                      <House className="icon" />
                    </div>
                    {/* <p>Mājiņas</p> */}
                  </CalendarSwitchBtn>
                  <div className="calendar-switch-divider" />
                  <CalendarSwitchBtn
                    onClick={() => setSelectedCalendar("tentsTrailers")}
                    className={selectedCalendar === "tentsTrailers" && "active"}
                  >
                    <div>
                      <Tent className="icon" />
                      <Plus className="icon" />
                      <Trailer className="icon" />
                    </div>
                    {/* <p>Teltis un treileri</p> */}
                  </CalendarSwitchBtn>
                </CalendarSwitchBtns>
              </CalendarSwitch>

              <Calendar
                localizer={localizer}
                events={reservations}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "3500px" }}
                components={{ event: CustomEvent, toolbar: CustomToolbar }}
                views={["month"]} // Only show month view
                step={60} // Time slot size in minutes
                timeslots={1} // Number of timeslots per hour
                dayPropGetter={dayPropGetter}
              />
            </div>
            <Footer />
          </DashLeft>
        </Dash>
      </motion.div>

      {modal && (
        <>
          <ModalEvent>
            <div className="modal">
              <CloseOutline
                className="close-icon"
                onClick={() => setModal(false)}
              />

              <div className="modal-item">
                <h3>Vieta</h3>

                <select
                  id="status"
                  style={{ width: "100%" }}
                  value={selectedEventData?.accommodationId}
                  onChange={(e) => {
                    setSelectedEventData((prevState) => ({
                      ...prevState,
                      accommodationId: e.target.value,
                    }));
                  }}
                >
                  <option value="1">1. mājiņa</option>
                  <option value="2">2. mājiņa</option>
                  <option value="3">3. mājiņa</option>
                  <option value="4">4. mājiņa</option>
                  <option value="5">5. mājiņa</option>
                  <option value="6">6. mājiņa</option>
                  <option value="7">7. mājiņa</option>
                  <option value="8">8. mājiņa</option>
                  <option value="9">9. mājiņa</option>
                  <option value="10">10. mājiņa</option>
                  <option value="11">11. mājiņa</option>
                  <option value="12">12. mājiņa</option>
                  <option value="13">Telšu vieta</option>
                  <option value="14">Treilera vieta</option>
                </select>
              </div>

              <div className="modal-item">
                <h3>Rezervācija veikta</h3>
                <input
                  type="text"
                  value={selectedEventData?.createdAt}
                  readOnly
                  disabled={true}
                />
              </div>

              <div className="modal-item">
                <h3>Vārds Uzvārds</h3>
                <input
                  type="text"
                  value={selectedEventData?.name}
                  onChange={(e) =>
                    setSelectedEventData((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="modal-item">
                <h3>Personu skaits</h3>
                <input
                  type="number"
                  value={selectedEventData?.persons}
                  pattern="[0-9]*"
                  onChange={(e) =>
                    setSelectedEventData((prevState) => ({
                      ...prevState,
                      persons: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="modal-item">
                <h3>E-pasts</h3>
                <input
                  type="text"
                  value={selectedEventData?.email}
                  onChange={(e) =>
                    setSelectedEventData((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }))
                  }
                />

                <a href={`mailto:${selectedEventData?.email}`}>
                  <EmailOutline className="icon" />
                  <p>Sūtīt e-pastu</p>
                </a>
              </div>

              <div className="modal-item">
                <h3>Telefona nr.</h3>
                <input
                  type="number"
                  value={selectedEventData?.phoneNumber}
                  onChange={(e) =>
                    setSelectedEventData((prevState) => ({
                      ...prevState,
                      phoneNumber: e.target.value,
                    }))
                  }
                />
                <a href={`tel:${selectedEventData?.phoneNumber}`}>
                  <PhoneCall className="icon" />
                  <p>Zvanīt</p>
                </a>
              </div>

              <div className="modal-item-large">
                <DateRangePicker
                  ranges={[selectionRange]}
                  weekStartsOn={1}
                  showMonthAndYearPickers={false}
                  locale={lv}
                  rangeColors={["var(--green)"]}
                  minDate={new Date()}
                  onChange={handleSelect}
                />
              </div>

              {/* <div className="modal-item">
                <h3>Rezervācija no</h3>
                <p className="reservation-data">{selectedEventData?.start}</p>
              </div>

              <div className="modal-item">
                <h3>Rezervācija līdz</h3>
                <p className="reservation-data">{selectedEventData?.end}</p>
              </div> */}

              <div className="modal-item">
                <h3>Cena, €</h3>
                <input
                  type="number"
                  placeholder={selectedEventData?.totalPrice}
                  value={selectedEventData?.totalPrice}
                  onChange={(e) =>
                    setSelectedEventData((prevState) => ({
                      ...prevState,
                      totalPrice: e.target.value,
                    }))
                  }
                  pattern="[0-9]*"
                />
              </div>

              <div className="modal-item">
                <h3>Status</h3>
                <select
                  id="status"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setSelectedEventData((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }))
                  }
                >
                  {selectedEventData?.status && (
                    <option value={selectedEventData?.status}>
                      {selectedEventData?.status === "Pending" &&
                        "Neapstiprināts"}
                      {selectedEventData?.status === "Confirmed" &&
                        "Apstiprināts"}
                      {selectedEventData?.status === "Completed" && "Pabeigts"}
                    </option>
                  )}

                  {/* Render the options excluding the current status */}
                  {selectedEventData?.status !== "Pending" && (
                    <option value="Pending">Neapstiprināts</option>
                  )}
                  {selectedEventData?.status !== "Confirmed" && (
                    <option value="Confirmed">Apstiprināts</option>
                  )}
                  {selectedEventData?.status !== "Completed" && (
                    <option value="Completed">Pabeigts</option>
                  )}
                </select>
              </div>

              <div className="modal-item-large">
                <h3>Komentārs</h3>
                <textarea
                  placeholder="Komentārs"
                  onChange={(e) =>
                    setSelectedEventData((prevState) => ({
                      ...prevState,
                      notes: e.target.value,
                    }))
                  }
                  value={selectedEventData?.notes}
                />
              </div>

              <div className="modal-btns">
                <div
                  className="modal-btn error"
                  onClick={() => {
                    const confirmed = window.confirm(
                      "Vai tiešām izdzēst rezervāciju? Tā tiks dzēsta neatgriezeniski."
                    );
                    if (confirmed) {
                      deleteReservation(selectedEventData?.bookingId);
                    }
                  }}
                >
                  <Delete className="icon" />
                  <p>Dzēst</p>
                </div>

                <div
                  className="modal-btn success"
                  onClick={() =>
                    // updateReservationData(selectedEventData?.bookingId)
                    updateReservationDataNew(selectedEventData?.bookingId)
                  }
                >
                  <Save className="icon" />
                  <p>Saglabāt</p>
                </div>
              </div>
            </div>
          </ModalEvent>
        </>
      )}
    </Wrapper>
  );
};

const RouterAndTime = styled.div`
  border-bottom: 1px solid var(--lightgrey);
  background-color: var(--dashback);

  .router-time-container {
    padding: 10px 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1250px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 760px) {
    top: 86px;
  }

  .heading-date {
    p {
      font-size: 0.9rem;
      color: var(--green);
      font-weight: 600;
      text-align: end;
    }
  }

  .heading-router {
    display: flex;
    gap: 5px;
    align-items: center;

    p {
      font-size: 0.9rem;
      color: var(--grey);
    }

    a {
      font-size: 0.9rem;
      color: var(--green);
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const CalendarSwitchBtn = styled.div`
  cursor: pointer;
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  padding: 10px;
  line-height: normal;
  white-space: nowrap;
  flex-grow: 1;

  .icon {
    max-width: 18px;
    width: 100%;
  }

  p {
    font-size: 0.9rem;
  }
`;

const CalendarSwitchBtns = styled.div`
  border: 1px solid var(--green);
  border-radius: 4px;
  display: flex;
  width: 100%;
  background-color: var(--white);

  .calendar-switch-divider {
    height: 100%;
    width: 1px;
    background: var(--green);
  }

  .active {
    background-color: var(--green);
    color: var(--white);
  }
`;

const CalendarSwitch = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (max-width: 650px) {
    left: 20px;
    transform: unset;
    bottom: 120px;
  }
`;

const CalendarHeading = styled.div`
  padding: 20px 20px 0 20px;

  h3 {
    font-size: 1.2rem;
  }

  .calendar-colors {
    margin: 15px 0 0 0;

    .color-heading {
      font-size: 0.9rem;
      margin: 10px 0 10px 0;
    }

    .calendar-colors-list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .calendar-color {
        display: flex;
        gap: 10px;

        .calendar-color-circle {
          max-width: 15px;
          width: 100%;
          height: 15px;
          border-radius: 50%;
          position: relative;
          top: 3px;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }
  }
`;

const ModalEvent = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--lightgrey2);
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 650px) {
    align-items: flex-start;
  }

  .rdrDateRangePickerWrapper {
    width: 100%;

    .rdrDateDisplayWrapper {
      background-color: var(--lightgrey2);
      border-radius: 5px;
    }

    .rdrDateDisplay {
      color: var(--green) !important;
    }

    .rdrCalendarWrapper {
      width: 100%;

      .rdrMonth {
        width: 100%;
      }

      .rdrDayDisabled .rdrDayNumber span {
        color: var(--lightgrey);
      }
    }
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .modal::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }
  .modal::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  .modal::-webkit-scrollbar-thumb {
    background: #1e4c42;
    border: 0px none #ffffff;
    border-radius: 14px;
  }
  .modal::-webkit-scrollbar-thumb:hover {
    background: #143e35;
  }
  .modal::-webkit-scrollbar-thumb:active {
    background: #174038;
  }
  .modal::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 0px;
  }
  .modal::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }
  .modal::-webkit-scrollbar-track:active {
    background: #ffffff;
  }
  .modal::-webkit-scrollbar-corner {
    background: transparent;
  }

  .modal {
    background-color: var(--white);
    max-width: 500px;
    width: 100%;
    margin: 0 20px;
    border: 1px solid var(--lightgrey);
    border-radius: 5px;
    padding: 40px 20px 30px 35px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    max-height: 90vh;
    overflow-y: scroll;

    @media only screen and (max-width: 650px) {
      max-width: 100%;
      max-height: 100vh;
      margin: 0;
      padding: 40px 20px 200px 20px;
    }

    .modal-btns {
      display: flex;
      gap: 10px;
      margin: 10px 0 0 0;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-end;

      @media only screen and (max-width: 650px) {
        margin: 40px 0 0 0;
      }

      .modal-btn {
        padding: 8px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        border: 1px solid transparent;

        .icon {
          max-width: 18px;
          width: 100%;
          color: var(--white);
        }

        p {
          color: var(--white);
          font-size: 0.9rem;
          white-space: nowrap;
        }
      }

      .modal-btn:hover {
        opacity: 0.7;
      }

      .success {
        background-color: var(--lightgreen);
        border-color: var(--green);

        p {
          color: var(--green);
        }

        .icon {
          color: var(--green);
        }
      }

      .error {
        background-color: var(--red);
      }
    }

    .placeholder {
      position: absolute;
      font-size: 0.9rem;
      top: 34px;
      left: 8px;
    }

    .price-input {
      padding-left: 20px;
    }

    input,
    select {
      padding: 10px 15px;
      border: 1px solid var(--white);
      outline: none;
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 0.9rem;
      color: var(--black);
      border-radius: 5px;
      background-color: var(--lightgrey2);
      max-width: 100%;
      width: 100%;
    }

    input:disabled {
      color: #6b6b6b;
    }

    textarea {
      padding: 10px 15px;
      border: 1px solid var(--white);
      outline: none;
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 0.9rem;
      color: var(--black);
      border-radius: 5px;
      background-color: var(--lightgrey2);
      min-width: 100% !important;
      resize: vertical;
      min-height: 200px;
    }

    .close-icon {
      max-width: 24px;
      width: 100%;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;

      @media only screen and (max-width: 650px) {
        right: 20px;
        top: 20px;
      }
    }

    .modal-item-large {
      width: 100%;

      h3 {
        font-size: 1rem;
        font-weight: 600;
        color: var(--green);
        margin: 0 0 10px 0;
      }

      .reservation-data-comment {
        font-size: 0.9rem;
      }
    }

    .modal-item {
      width: 48%;
      margin: 0 0 10px 0;
      position: relative;

      @media only screen and (max-width: 650px) {
        width: 100%;
      }

      h3 {
        font-size: 1rem;
        font-weight: 600;
        color: var(--green);
        margin: 0 0 5px 0;
      }

      .reservation-data {
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a {
        padding: 8px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        border: 1px solid transparent;
        width: 100%;
        text-decoration: none;
        background: var(--lightgreen);
        margin: 10px 0 0 0;
        cursor: pointer;

        p {
          font-size: 0.85rem;
          color: var(--green);
        }

        .icon {
          max-width: 18px;
          width: 100%;
          color: var(--green);
        }
      }

      a:hover {
        border-color: var(--green);
      }
    }
  }
`;

const CalendarEvent = styled.div`
  padding: 5px 10px;
  border-radius: 5px;

  h3 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.75rem;
  }
`;

const DashLeft = styled.div`
  flex: 1;

  .rbc-calendar {
    width: 100%;
    padding: 20px;
    margin-bottom: 40px;

    .rbc-month-view {
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13),
        0 1px 2px 0 rgba(0, 0, 0, 0.11);
      border-radius: 5px;
    }

    .rbc-btn-group {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 110;

      @media only screen and (max-width: 650px) {
        right: 20px;
        left: unset;
        transform: unset;
        bottom: 120px;
      }

      button {
        padding: 10px;
        background-color: var(--white);
      }
    }

    .rbc-date-cell {
      text-align: center;

      button {
        font-size: 1.2rem;
      }
    }

    .rbc-event-content {
      overflow: hidden !important;
    }

    @media only screen and (max-width: 650px) {
      width: 100vw;
      padding: 0;
    }

    .rbc-button-link {
      font-size: 1.4rem;
      font-weight: 600;
      padding: 15px;
    }

    .rbc-header {
      padding: 20px;
    }

    .rbc-event {
      background-color: transparent !important;
    }

    .rbc-today {
      background-color: var(--lightgreen) !important;
    }

    .rbc-toolbar-label {
      margin: 40px 0 20px;
      font-weight: 600;
      text-transform: capitalize;
      font-size: 1.2rem;
      color: var(--green);
    }
  }
`;

const Dash = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 300px 0;
  max-width: 1250px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  @media only screen and (max-width: 760px) {
    padding: 0;
  }

  .confetti {
    z-index: 200 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }

  .rbc-toolbar button {
    padding: 10px 15px;
    border-color: var(--green);
    cursor: pointer;
    font-size: 0.8rem;

    .icon {
      max-width: 18px;
      width: 100%;
    }
  }

  .rbc-toolbar button:hover {
    background-color: var(--lightgreen);
  }
`;

export default Reservations;
