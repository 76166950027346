import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//icons
import { House } from "@styled-icons/fa-solid/House";
import { Tent } from "@styled-icons/fluentui-system-filled/Tent";
import { Water } from "@styled-icons/boxicons-regular/Water";
import { Trailer } from "@styled-icons/fa-solid/Trailer";

//translation
import { useTranslation } from "react-i18next";

const MainPoints = () => {
  const { t, i18n } = useTranslation();

  const points = [
    {
      Icon: House,
      name: t("bullet_1"),
      desc: t("bullet_1p"),
      link: "/houses",
    },
    {
      Icon: Tent,
      name: t("bullet_2"),
      desc: t("bullet_2p"),
      link: "/tents",
    },
    {
      Icon: Trailer,
      name: t("bullet_3"),
      desc: t("bullet_3p"),
      link: "/trailers",
    },
    {
      Icon: Water,
      name: t("bullet_4"),
      desc: t("bullet_4p"),
      link: "/map",
    },
  ];

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      {points?.map((point, index) => (
        <Link to={point?.link}>
          <div key={index} className="point-single">
            <div>
              <div className="point-single-icon">
                <point.Icon className="icon" />
              </div>
            </div>

            <h3>{point.name}</h3>

            <p>{point.desc}</p>
          </div>
        </Link>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 10px;
  display: flex;
  align-items: top;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }

  .point-single:hover {
    .point-single-icon {
      background-color: var(--lightgreen);

      .icon {
        color: var(--green);
      }
    }
  }

  .point-single {
    max-width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .point-single-icon {
      background-color: var(--green);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      transition: 0.3s linear;

      .icon {
        max-width: 32px;
        width: 100%;
        color: var(--white);
        transition: 0.3s linear;
      }
    }

    h3 {
      color: var(--black);
      font-size: 1.2rem;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 0.9rem;
      color: var(--black);
    }
  }
`;

export default MainPoints;
