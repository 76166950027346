import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t, i18n } = useTranslation();

  const [isCheckedPolicy, setIsCheckedPolicy] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <div className="heading">
        <h3>{t("contact_h3")}</h3>
        <p>{t("contact_p")}</p>
      </div>

      <div className="contact-wrapper">
        <div className="map">
          <iframe
            className="map-google"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7194.6728380393415!2d22.62509199733984!3d57.58065162355096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ee11de19a4cc37%3A0xf99d827cdaea9eb8!2sKempings%20Plaucaki!5e0!3m2!1sen!2sfi!4v1712762308178!5m2!1sen!2sfi"
            title="Google Maps"
            loading="lazy"
            allowfullscreen=""
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <form className="contact-form">
          <input
            type="text"
            name="name"
            placeholder={t("contact_p1")}
            maxLength={50}
            required
          />
          <input
            type="email"
            name="email"
            placeholder={t("contact_p2")}
            maxLength={50}
            required
          />
          <input
            type="number"
            name="number"
            placeholder={t("contact_p3")}
            maxLength={50}
            required
          />
          <textarea
            name="message"
            placeholder={t("contact_p4")}
            maxLength={500}
            required
          ></textarea>
          <label className="checkmark-container">
            <p>
              {t("contact_p5")}{" "}
              <Link to="/privacy-policy" target="_blank">
                {t("contact_p6")}
              </Link>
            </p>
            <input
              onClick={() =>
                setIsCheckedPolicy((isCheckedPolicy) => !isCheckedPolicy)
              }
              type="checkbox"
              checked={isCheckedPolicy}
              readOnly
              required
            />
            <span className="checkmark"></span>
          </label>
          <button type="submit">{t("contact_p7")}</button>
        </form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0px;

  @media only screen and (max-width: 650px) {
    padding: 20px 0;
  }

  .heading {
    margin: 20px 0;
    padding: 20px;

    h3 {
      font-size: 1.6rem;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  .contact-wrapper {
    display: flex;

    @media only screen and (max-width: 650px) {
      flex-direction: column;
    }

    .checkmark-container input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark-container input:checked ~ .checkmark {
      background-color: var(--white);
    }

    .checkmark-container:hover input ~ .checkmark {
      background-color: var(--white);
    }

    .checkmark-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 0.9rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      p {
        font-size: 0.9rem;
        color: var(--white);
        line-height: 1.4;
        position: relative;
        top: 3px;

        a {
          color: var(--white);
        }
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 7px;
        height: 12px;
        border: solid var(--green);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 5px;
        background-color: var(--white);
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }

    .contact-form {
      background-color: var(--green);
      padding: 3% 5%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      @media only screen and (max-width: 650px) {
        padding: 8% 5%;
      }

      button {
        outline: none;
        border: none;
        padding: 12px 15px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        color: var(--green);
        font-size: 0.9rem;
        font-weight: 600;
        background-color: var(--white);
      }

      button:hover {
        opacity: 0.8;
      }

      input,
      textarea {
        min-width: 100%;
        padding: 15px;
        border: 1px solid var(--white);
        outline: none;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 0.9rem;
        color: var(--black);
        border-radius: 5px;
      }

      input:focus::placeholder,
      textarea:focus::placeholder {
        color: black; /* Change to the desired color when focused */
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      textarea {
        resize: none;
        min-height: 200px;
      }

      input::placeholder,
      textarea::placeholder {
        color: var(--grey);
      }
    }

    .map {
      min-height: 350px;

      @media only screen and (max-width: 650px) {
        max-height: 350px;
      }

      .map-google {
        width: 100%;
        height: 100%;
        border: none;

        @media only screen and (max-width: 650px) {
          height: 350px;
        }
      }
    }

    .map,
    .contact-form {
      flex-basis: 50%;
    }
  }
`;

export default Contact;
