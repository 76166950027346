import { useState, useEffect } from "react";
import axios from "axios";

//api
import { getUser } from "../../api/api-list";

//redux
import { selectUser, setUser } from "../redux/variables";
import { useDispatch, useSelector } from "react-redux";

const useAuth = () => {
  const [loggedIn, setLoggedIn] = useState(null);

  const token = localStorage.getItem("token");
  const expiry = localStorage.getItem("token_expires_at");

  //user data
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const getUserData = () => {
    const apiUrl = `${getUser}${token}`; // Replace with your actual API endpoint URL

    // Make a GET request to the API endpoint
    axios
      .get(apiUrl, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setUser(data[0]));
      });
    // .catch((error) => {
    //   // If an error occurs, set the error state with the error message
    //   console.log(error);
    // });
  };

  useEffect(() => {
    if (token && expiry) {
      const timestamp = expiry;
      const milliseconds = timestamp * 1000;
      const dateTokenExpires = new Date(milliseconds);
      const currentDate = new Date();

      if (user === null) {
        getUserData();
      }

      if (dateTokenExpires > currentDate) {
        setLoggedIn(true);
      } else if (dateTokenExpires < currentDate) {
        localStorage.removeItem("token");
        localStorage.removeItem("token_expires_at");
        setLoggedIn(false);
      }
    } else {
      setLoggedIn(false);
    }

    //eslint-disable-next-line
  }, []);

  return loggedIn;
};

export default useAuth;
