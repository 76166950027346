import React, { useEffect } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { Element } from "react-scroll";

//translation
import { useTranslation } from "react-i18next";

const MoreInformationBanners = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Element name="reserve" id="reserve">
        <div className="more-info-banner" id="reserve">
          <div className="more-info-banner-image">
            <img
              src="../images/more-information-banners/0.png"
              alt="banner-img"
            />
          </div>

          <div
            className="more-info-banner-data"
            style={{ backgroundColor: "var(--lightgreen)" }}
          >
            <h3 style={{ color: "var(--green)" }}>{t("learn_houses_h3")}</h3>
            <div classname="more-info-banner-data-desc">
              <p style={{ color: "var(--green)" }}>{t("learn_houses_p1")}</p>
              <p style={{ color: "var(--green)" }}>{t("learn_houses_p2")}</p>
              <p style={{ color: "var(--green)" }}>{t("learn_houses_p3")}</p>
              <p style={{ color: "var(--green)" }}>{t("learn_houses_p4")}</p>
              <p style={{ color: "var(--green)", marginTop: 25 }}>
                {t("learn_houses_p5")}
              </p>
            </div>
            <div>
              <Link
                to="/houses"
                className="btn"
                style={{
                  color: "var(--lightgreen)",
                  backgroundColor: "var(--green)",
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("learn_more")}
              </Link>
            </div>
          </div>
        </div>

        <div
          className="more-info-banner more-info-banner-reverse"
          style={{
            flexDirection: window.innerWidth < 600 ? "column" : "row-reverse",
          }}
        >
          <div className="more-info-banner-image">
            <img
              src="../images/more-information-banners/1.png"
              alt="banner-img"
            />
          </div>

          <div
            className="more-info-banner-data"
            style={{
              backgroundColor: "var(--green)",
              alignItems: window.innerWidth < 600 ? "end" : "start",
            }}
          >
            <h3 style={{ color: "var(--white)" }}>{t("learn_trailers_h3")}</h3>
            <div classname="more-info-banner-data-desc">
              <p style={{ color: "var(--white)" }}>{t("learn_trailers_p1")}</p>
            </div>
            <div>
              <Link
                to="/trailers"
                className="btn"
                style={{
                  color: "var(--green)",
                  backgroundColor: "var(--white)",
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("learn_more")}
              </Link>
            </div>
          </div>
        </div>

        <div className="more-info-banner">
          <div className="more-info-banner-image">
            <img
              src="../images/more-information-banners/2.png"
              alt="banner-img"
            />
          </div>

          <div
            className="more-info-banner-data"
            style={{ backgroundColor: "var(--whitesmoke)" }}
          >
            <h3 style={{ color: "var(--green)" }}>{t("learn_tents_h3")}</h3>
            <div classname="more-info-banner-data-desc">
              <p style={{ color: "var(--green)" }}>{t("learn_tents_p1")}</p>
            </div>
            <div>
              <Link
                to="/tents"
                className="btn"
                style={{
                  color: "var(--white)",
                  backgroundColor: "var(--green)",
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("learn_more")}
              </Link>
            </div>
          </div>
        </div>
      </Element>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0px;

  .more-info-banner {
    display: flex;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    .more-info-banner-image {
      max-width: 500px;

      @media only screen and (max-width: 920px) {
        max-width: 300px;
      }

      @media only screen and (max-width: 660px) {
        max-width: 200px;
      }

      @media only screen and (max-width: 600px) {
        max-width: 100%;
        max-height: 250px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media only screen and (max-width: 600px) {
          max-height: 250px;
        }
      }
    }

    .more-info-banner-data {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      padding: 5%;

      @media only screen and (max-width: 600px) {
        padding: 30px 5%;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 600;
      }

      p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 5px;
      }

      h3,
      p {
        max-width: 450px;
        line-height: 1.4;
      }

      .btn {
        text-decoration: none;
        border-radius: 5px;
        padding: 8px 15px;
        font-size: 0.9rem;
        font-weight: 600;
        display: inline-block;
      }

      .btn:hover {
        opacity: 0.8;
      }
    }
  }
`;

export default MoreInformationBanners;
