import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//icons
import { MapLocationDot } from "@styled-icons/fa-solid/MapLocationDot";
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp";

const Map = () => {
  return (
    <Wrapper>
      <div className="global-btns">
        <Link
          to="/map"
          onClick={() => window.scrollTo(0, 0)}
          style={{ marginBottom: 10 }}
        >
          <MapLocationDot className="icon" />
        </Link>

        <Link
          to="https://wa.me/+37126445124"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Whatsapp className="icon" />
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .global-btns {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 110;
  }

  a {
    background-color: var(--green);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
  }

  .icon {
    max-width: 28px;
    width: 100%;
    color: white;
  }
`;

export default Map;
