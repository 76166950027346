import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

//components
import PageHeading from "../components/PageHeading";

//translation
import { useTranslation } from "react-i18next";

const Rules = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  const rules = [
    t("rules_p1"),
    t("rules_p2"),
    t("rules_p3"),
    t("rules_p4"),
    t("rules_p5"),
    t("rules_p6"),
    t("rules_p7"),
    t("rules_p8"),
    t("rules_p9"),
    t("rules_p10"),
    t("rules_p11"),
    t("rules_p12")
  ];

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("rules_title")}</title>
      </Helmet>
      <PageHeading
        image={"../images/page-headings/rules.png"}
        imagePosition={"70% 50%"}
        text={t("rules_heading")}
      />

      <ul className="rules-list">
        {rules?.map((rule, i) => (
          <li key={i}>{rule}</li>
        ))}
      </ul>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;

  .rules-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 40px 40px;
    list-style-type: decimal;
  }
`;

export default Rules;
