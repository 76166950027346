import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

//components
import LandingSlideShow from "../components/LandingSlideShow";
import MainPoints from "../components/MainPoints";
import MoreInformationBanners from "../components/MoreInformationBanners";
import Activities from "../components/Activities";
import Gallery from "../components/Gallery";
import Contact from "../components/Contact";
import Reviews from "../components/Reviews";

//translation
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang === null) {
      localStorage.setItem("lang", "latvian");
      i18n.changeLanguage("latvian");
    } else {
      i18n.changeLanguage(lang);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("home_title")}</title>
      </Helmet>
      <LandingSlideShow />
      <MainPoints />
      <MoreInformationBanners />
      <Activities />
      <Gallery />
      <Reviews />
      <Contact />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-x: hidden;
`;

export default Home;
