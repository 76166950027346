import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "../images/gallery/0.jpg",
    original: "../images/gallery/0.jpg",
    width: 420,
    height: 274,
  },
  {
    src: "../images/gallery/1.jpg",
    original: "../images/gallery/1.jpg",
    width: 420,
    height: 274,
  },
  {
    src: "../images/gallery/2.jpg",
    original: "../images/gallery/2.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "../images/gallery/3.jpg",
    original: "../images/gallery/3.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/4.jpg",
    original: "../images/gallery/4.jpg",
    width: 320,
    height: 183,
  },
  {
    src: "../images/gallery/5.jpg",
    original: "../images/gallery/5.jpg",
    width: 240,
    height: 320,
  },
  {
    src: "../images/gallery/6.jpg",
    original: "../images/gallery/6.jpg",
    width: 320,
    height: 190,
  },
  {
    src: "../images/gallery/7.jpg",
    original: "../images/gallery/7.jpg",
    width: 320,
    height: 148,
  },
  {
    src: "../images/gallery/8.jpg",
    original: "../images/gallery/8.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/9.jpg",
    original: "../images/gallery/9.jpg",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
  },
  {
    src: "../images/gallery/10.jpg",
    original: "../images/gallery/10.jpg",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
  },
  {
    src: "../images/gallery/11.jpg",
    original: "../images/gallery/11.jpg",
    alt: "Wood Glass",
    width: 313,
    height: 320,
  },
  {
    src: "../images/gallery/12.jpg",
    original: "../images/gallery/12.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/13.jpg",
    original: "../images/gallery/13.jpg",
    width: 320,
    height: 194,
  },
  {
    src: "../images/gallery/14.jpg",
    original: "../images/gallery/14.jpg",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/15.jpg",
    original: "../images/gallery/15.jpg",
    width: 271,
    height: 320,
  },
  {
    src: "../images/gallery/16.jpg",
    original: "../images/gallery/16.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/17.jpg",
    original: "../images/gallery/17.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/18.jpg",
    original: "../images/gallery/18.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/19.jpg",
    original: "../images/gallery/19.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/20.jpg",
    original: "../images/gallery/20.jpg",
    width: 320,
    height: 179,
  },
  {
    src: "../images/gallery/21.jpg",
    original: "../images/gallery/21.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/22.jpg",
    original: "../images/gallery/22.jpg",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
  },
  {
    src: "../images/gallery/23.jpg",
    original: "../images/gallery/23.jpg",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
  },
  {
    src: "../images/gallery/24.jpg",
    original: "../images/gallery/24.jpg",
    alt: "Wood Glass",
    width: 313,
    height: 320,
  },
  {
    src: "../images/gallery/25.jpg",
    original: "../images/gallery/25.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/26.jpg",
    original: "../images/gallery/26.jpg",
    width: 320,
    height: 194,
  },
  {
    src: "../images/gallery/27.jpg",
    original: "../images/gallery/27.jpg",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/28.jpg",
    original: "../images/gallery/28.jpg",
    width: 271,
    height: 320,
  },
  {
    src: "../images/gallery/29.jpg",
    original: "../images/gallery/29.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/30.jpg",
    original: "../images/gallery/30.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/31.jpg",
    original: "../images/gallery/31.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/32.jpg",
    original: "../images/gallery/32.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/33.jpg",
    original: "../images/gallery/33.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/34.jpg",
    original: "../images/gallery/34.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/35.jpg",
    original: "../images/gallery/35.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/36.jpg",
    original: "../images/gallery/36.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/37.jpg",
    original: "../images/gallery/37.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/38.jpg",
    original: "../images/gallery/38.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/39.jpg",
    original: "../images/gallery/39.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/40.jpg",
    original: "../images/gallery/40.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "../images/gallery/41.jpg",
    original: "../images/gallery/41.jpg",
    width: 320,
    height: 213,
  },
];
